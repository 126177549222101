import { ArrowBackIos, CopyAll } from "@mui/icons-material";
import React, { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIos from "@mui/icons-material/ArrowForwardIos";
const TableContents = ({
  data,
  user,
  currentData,
  imgSources,
  headers,
  copyTrue,
  clickable,
}) => {
  const navigate = useNavigate();
  let rows = currentData.map((item) => Object.values(item));
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkMobile();
  }, []);
  const processCellValue = (cell) => {
    if (typeof cell === "object" && cell !== null) {
      return JSON.stringify(cell);
    }
    return cell;
  };
  headers = headers ? headers.slice(0, isMobile ? 3 : headers.length) : "";
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = isMobile ? 4 : 6;
  const totalPages = Math.ceil(rows.length / itemsPerPage);

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const paginatedRows = rows.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <div className="overflow-hidden w-full">
      <table className="table-auto min-w-full border-collapse rounded-xl">
        <thead className="bg-white border-t-2 border-b border-[#101828] border-solid border-[2px] rounded-t-xl">
          <tr>
            {headers.map((header, index) => (
              <th
                key={index}
                className="border-b pl-2 md:pl-none px-2 py-4 md:px-6 md:py-4 text-left text-black rounded-t-xl"
              >
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {paginatedRows.map((row, rowIndex) => (
            <tr
              key={rowIndex}
              onClick={() => {
                clickable &&
                  navigate("/dashboard/agent-details/", {
                    state: {
                      user: user,
                      agent: data[(currentPage - 1) * itemsPerPage + rowIndex],
                    },
                  });
              }}
              className="border-b border-solid  border-[#1D2939] rounded-xl jtable-fix"
            >
              {row.slice(0, isMobile ? 3 : 6).map((cell, cellIndex) => (
                <td
                  className="border-b px-0.5 pl-2 md:pl-none md:px-6 py-4 text-left"
                  key={cellIndex}
                >
                  <div
                    className={`flex items-center  ${
                      cellIndex === 2 && processCellValue(cell) === "Active"
                        ? "bg-green-200 items-center w-3/4 text-xs md:text-md md:px-4  justify-center py-1.5 rounded-xl  text-black"
                        : "gap-x-3"
                    }`}
                  >
                    {cellIndex === 0 && imgSources && (
                      <img
                        src={imgSources[rowIndex] ?? "/femaleAvatar.png"}
                        alt="Avatar"
                        className="h-8 w-8 rounded-full"
                        onError={(e) => (e.target.src = "/femaleAvatar.png")}
                      />
                    )}
                    <span className="break-words text-xs text-black   md:text-base">
                      {processCellValue(cell)}
                    </span>
                  </div>
                </td>
              ))}
              {copyTrue && (
                <td className=" px-6 py-4 text-left hidden md:flex items-center text-black cursor-pointer">
                  <CopyAll />
                  Duplicate
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
      <div className="bg-white text-black  py-2 px-6 border-t-0 border-b-2 border-[#101828] border-solid border-[2px] rounded-b-xl flex justify-between items-center">
        <div>
          Showing {itemsPerPage <= data.length ? itemsPerPage : data.length} out
          of {data.length}
        </div>
        <div className="flex justify-between items-center">
          <div
            onClick={handlePrevPage}
            className={`text-white  py-1 rounded-md ${
              currentPage === 1 && "opacity-50 cursor-not-allowed"
            }`}
            disabled={currentPage === 1}
          >
            <ArrowBackIos className="text-sm pt-1" />
          </div>
          <div className="px-2 text-xl">{currentPage}</div>
          <div
            onClick={handleNextPage}
            className={`  cursor-pointer py-1 rounded-md ${
              currentPage === totalPages && "opacity-50 cursor-not-allowed"
            }`}
            disabled={currentPage === totalPages}
          >
            <div className="">
              <ArrowForwardIos className="text-sm pt-1" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableContents;

import React from "react";
import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  InputLabel,
  Select,
  Input,
  Box,
  Chip,
  MenuItem,
} from "@mui/material";
import { Cancel } from "@mui/icons-material";
import { useState } from "react";
import InputComponent from "../InputComponent";

const FollowUpSteps = ({
  isLatencyOptimizatied,
  setisLatencyOptimizatied,
  extraction,
  webhook,
  setWebHook,
  setExtraction,
  setSelectedTasks,
  selectedTasks,
}) => {
  const handleTasksChange = (event) => {
    let { name, value } = event.target;
    if (name === "extractionDetails") {
      setSelectedTasks(value);
      console.log("Extracting details");
    } else if (value?.includes("webhook")) {
      console.log("Webhook URL", value);
      value = ["extraction", ...value];
      console.log("Webhook URL", value);
      setSelectedTasks(value);
    } else {
      setSelectedTasks(value);
    }
  };
  const handleDelete = (e, value) => {
    console.log(e, value);
    setSelectedTasks((prev) => {
      return prev.filter((task) => task !== value);
    });
  };
  return (
    <div className="w-full overflow-x-auto  z-50">
      <div className="relative pb-7 flex justify-center items-center py-2 flex-col gap-y-2   w-full  ">
        <FormControl
          sx={{
            width: "60%",
            margin: "normal",
            color: "white",
            "& .MuiInputLabel-root": {
              color: "white",
              "&.Mui-focused": {
                color: "black",
              },
            },
            "& .MuiSelect-select": {
              color: "white",
              padding: "10px",
            },

            "& .MuiChip-root": {
              backgroundColor: "black",
              color: "white",
            },
            "& .MuiSelect-icon": {
              color: "black",
            },

            "& .MuiMenuItem-root": {
              color: "black",
              "&:hover": {
                backgroundColor: "black",
              },
            },
            "& .css-c0qdk6-MuiButtonBase-root-MuiMenuItem-root": {
              color: "black",
            },

            "& .css-xksckw-MuiInputBase-root-MuiInput-root:after": {
              opacity: 0,
            },
            "& .MuiAutocomplete-groupUl ": {
              color: "black",
            },
            "& .MuiAutocomplete-listbox": {
              backgroundColor: "black",

              "& .MuiAutocomplete-option": {
                color: "black",
              },
            },
            "& .MuiAutocomplete-popupIndicator": {
              color: "black",
            },
            "& .MuiAutocomplete-clearIndicator": {
              color: "black",
            },
          }}
          margin="normal"
        >
          <InputLabel>Tasks</InputLabel>
          <Select
            inputProps={{
              MenuProps: {
                MenuListProps: {
                  sx: {
                    backgroundColor: "black",
                    color: "white",
                  },
                },
              },
            }}
            multiple
            value={selectedTasks ?? []}
            onChange={handleTasksChange}
            input={
              <Input
                variant="standard"
                id="select-multiple-chip"
                label="Chip"
                className="bg-white border-2 border-black border-solid rounded-lg"
              />
            }
            renderValue={(selected) => (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {selected.map((value) => (
                  <Chip
                    clickable
                    deleteIcon={
                      <Cancel
                        onMouseDown={(event) => event.stopPropagation()}
                        className="text-white cursor-pointer"
                      />
                    }
                    className=" text-white"
                    onDelete={(e) => handleDelete(e, value)}
                    onClick={() => console.log("clicked chip")}
                    key={value}
                    label={value}
                  />
                ))}
              </Box>
            )}
          >
            <MenuItem value="summarization">Summarization</MenuItem>
            <MenuItem value="webhook">Webhook</MenuItem>
            <MenuItem value="extraction">Extraction</MenuItem>
          </Select>
        </FormControl>

        <div className=" flex justify-center w-full">
          {selectedTasks?.includes("extraction") && (
            <InputComponent
              label="Extraction"
              inputValue={extraction}
              setInputValue={(e) => {
                setExtraction(e.target.value);
              }}
            />
          )}
        </div>
        <div className=" w-full flex justify-center">
          {selectedTasks?.includes("webhook") && (
            <InputComponent
              label="Webhook"
              inputValue={webhook}
              setInputValue={(e) => setWebHook(e.target.value)}
            />
          )}
        </div>

        <div className="bg-[#fff] border-2 border-black border-solid p-2  mt-8 text-black text-sm flex  justify-center items-center  gap-x-2 w-[95%]">
          <div className="flex flex-col gap-y-2">
            <p className="text-semibold">
              Kindly mention the details you'd like to extract from the
              transcript of conversation in a list and give examples for AI to
              understand.
            </p>
            <p>
              1) Webhook payload - If you've chosen webhook trigger as a
              followup task as well, make sure that your extraction prompt
              triggers the task. Explicitly mention the json keys required.
            </p>
            <p>
              2) For example if you'd like to extract user intent then mention
              user intent - intent for the user to come back on app.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FollowUpSteps;

import React from "react";
import InputComponent from "../InputComponent";
import { useState } from "react";
import { Slider } from "@mui/material";
function LlmSettingsSteps({
  maxTokens,
  setMaxTokens,
  temperature,
  setTemperature,
}) {
  return (
    <div className="flex flex-col gap-y-7 pt-8 pb-6">
      <InputComponent
        label="Max Tokens"
        inputValue={maxTokens}
        setInputValue={(e) => {
          setMaxTokens(e.target.value);
        }}
        type="Number"
      />
      <div className="w-full flex justify-center items-center relative pt-3">
        <p className="absolute  -top-[21%] left-[20%] text-sm text-black ">
          Temperature
        </p>
        <div className="w-3/5 flex items-center justify-center gap-x-3 relative">
          <Slider
            defaultValue={temperature}
            value={temperature}
            onChange={(e, value) => setTemperature(value)}
            step={0.01}
            min={0}
            max={1}
            className=" h-2"
            sx={{
              color: "primary.main",
              "& .MuiSlider-thumb": {
                backgroundColor: "black",
              },
              "& .MuiSlider-track": {
                backgroundColor: "black",
              },
              "& .MuiSlider-rail": {
                backgroundColor: "grey.700",
              },
            }}
          />
          <p className="absolute text-right text-black -right-10">
            {temperature}
          </p>
        </div>
      </div>
    </div>
  );
}

export default LlmSettingsSteps;

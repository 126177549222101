import React from "react";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Info from "@mui/icons-material/Info";
import { Mixpanel } from "../utils/mixpanel";
import createApiInstance from "../utils/api";

function CallScreen({ toggleCallScreen, agent, accessToken }) {
  const [phoneNumber, setPhoneNumber] = useState("");
  const api = createApiInstance(accessToken);
  const handleConfirm = async () => {
    Mixpanel.track("call_btn_click", {
      agent: agent,
      state: "confirm",
    });

    if (phoneNumber !== "") {
      try {
        Mixpanel.track("call_btn_click", {
          agent: agent,
          state: "request",
        });

        const response = await api.post("/call", {
          agent_id: agent.id,
          recipient_phone_number: phoneNumber,
        });

        if (response.status !== 200) {
          throw new Error("Network response was not ok");
        }

        Mixpanel.track("call_btn_click", {
          agent: agent,
          state: "success",
        });

        toggleCallScreen();
      } catch (error) {
        // Handle errors here
        console.error("There was an error making the API call");
        Mixpanel.track("call_btn_click", {
          agentId: agent.id,
          state: "error",
          error: error?.response?.data?.message,
        });
        alert(error);
      }
    }
  };
  const handlePhoneNumberChange = (value) => {
    setPhoneNumber(value);
  };
  return (
    <div className="fixed inset-0 flex items-center justify-center  z-50  bg-opacity-50 flex flex-col rounded-lg ">
      <div className="rounded-lg pb-4 shadow-lg w-[95%] md:w-[40%] h-[60%] md:h-[40%]    bg-white text-black relative  ">
        <div className="flex justify-between items-center px-3  bg-black text-white py-4 rounded-lg border-b-2  border-solid border-black">
          <div>
            <h3>Trial Call</h3>
          </div>
          <div
            className="cursor-pointer"
            onClick={() => {
              toggleCallScreen();
            }}
          >
            <CloseIcon />
          </div>
        </div>
        <div className=" flex flex-col justify-center items-center pt-4 w-full gap-y-3  mx-auto ">
          <div className="w-[85%] justify-center flex flex-col gap-y-2">
            <label className=" pl-1 block text-sm" htmlFor="phone-input">
              Enter your phone number
            </label>

            <div className="items-center flex justify-center">
              <PhoneInput
                disableDropdown={true}
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
                className=" w-full p-2  "
                country={"us"}
                onlyCountries={["us"]}
                enableAreaCodes={true}
                inputClass="form-control  bg-white text-black border-none border-2 border-solid border-black"
                buttonClass=" bg-white border-none  "
                dropdownClass=" bg-white text-black hover:bg-white"
                containerClass="  bg-white react-tel-input  text-black border-2 border-solid border-black"
                searchClass="bg-white text-black"
              />
            </div>

            <label className=" p-1 text-sm" htmlFor="phone-input">
              Your phone number with country code
            </label>
          </div>
          <div className="bg-white p-2 text-black border-2 border-solid border-black text-sm flex items-center  gap-x-2 w-[85%]">
            <Info />
            <p>
              For demo purposes, test calls will be limited per user. Get
              credits or use your own Twilio keys to remove this limitation.
            </p>
          </div>
          <div
            className=" bg-black w-[85%] text-white cursor-pointer flex  justify-center items-center  text-center rounded-md gap-x-2 my-1 px-4 py-2"
            onClick={() => {
              handleConfirm();
            }}
          >
            <p className="text-center">Confirm</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CallScreen;

import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Button,
  Dialog,
  TextField,
  DialogContentText,
  CircularProgress,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import CustomTabs from "../components/CustomTabs";
import Analytics from "./assistant-details/Analytics";
import RunsTable from "./assistant-details/RunsTable";
import RequestLogs from "./assistant-details/RequestLogs";
import BatchCall from "./assistant-details/BatchCall";
import ChatComponent from "../components/ChatComponent"; // Import your ChatComponent
import CallComponent from "../components/CallComponent"; // Import your CallComponent
import AgentFormStepper from "../components/AgentFormStepper";
import { convertToCreateAgentForm, convertToText } from "../utils/utils";
import createApiInstance from "../utils/api";
import { Mixpanel } from "../utils/mixpanel";
import WebsocketComponent from "../components/WebsocketComponent";
import InfoIcon from "@mui/icons-material/Info";
import { useParams } from "react-router-dom";
import Subheader from "../components/icons/HeaderBreadCrumb/subheader";
import ChatScreen from "../components/ChatScreen";
import CallScreen from "../components/CallScreen";
import { AddIcCall } from "@mui/icons-material";
import ExecutionTable from "../components/ExcutionTable";
import PhoneCallbackTwoToneIcon from "@mui/icons-material/PhoneCallbackTwoTone";
import InboundUrl from "../components/inBoundUrl";
import AgentPrompt from "../components/AgentPrompt";
function AgentDetails({ accessToken }) {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const agent = location.state?.agent;
  let [formData, setFormData] = useState(convertToCreateAgentForm(agent));
  const user = location.state?.user;
  const userId = user.user_id;
  const isPaidUser = user?.paid_user || false;
  const [isPlaygroundOpen, setIsPlaygroundOpen] = useState(false);
  const [isCallScreenOpen, setIsCallScreenOpen] = useState(false);
  const [openWebcall, setOpenWebcall] = useState(false);
  const agentId = agent.id;
  const [rawData, setRawData] = useState([]);
  const [showEditAgent, setShowEditAgent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [runData, setRunData] = useState([]);
  const [prompt, setPrompt] = useState(null);
  //const { handleClick } = CallComponent({ agentId, accessToken });
  const [activeTab, setActiveTab] = useState(0);
  const [openAnalyticsDialog, setOpenAnalyticsDialog] = useState(false);
  const api = createApiInstance(accessToken);
  const [open, setOpen] = React.useState(false);

  const isTelephonyAgent =
    agent.tasks[0]["tools_config"]["input"]["provider"] == "twilio";

  const inboundVoiceUrl = `${process.env.REACT_APP_API_HOST}/inbound_call?ws_url=${process.env.REACT_APP_WS_HOST}&user_id=${userId}&agent_id=${agentId}`;

  const handleAnalyticsDialogClickOpen = () => {
    setOpenAnalyticsDialog(true);
  };

  const handleAnalyticsDialogClose = () => {
    setOpenAnalyticsDialog(false);
  };

  const togglePlayground = () => {
    setIsPlaygroundOpen(!isPlaygroundOpen);
  };
  const toggleCallScreen = () => {
    setIsCallScreenOpen(!isCallScreenOpen);
  };
  const [isInboundCallOpen, setIsInboundCallOpen] = useState(false);
  const toggleInboundCall = () => {
    setIsInboundCallOpen(!isInboundCallOpen);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [activeSubHeader, setActiveSubHeader] = useState("Agent Execution");
  useEffect(() => {
    const fetchPromptData = async () => {
      setLoading(true);
      const fetchData = async () => {
        try {
          setLoading(true);
          const response = await api.get(`/agent/${agentId}/executions`);
          let runs = [...response.data];
          if (runs.length > 0) {
            const extractedValues = runs.map((item) => ({
              Runid: item.id,
              RunData: item.extracted_data.date,
              TotalCost: item.total_cost?.toFixed(2),
              RunTime: item.conversation_time?.toFixed(2),
            }));
            setRunData(extractedValues);
            setRawData(runs);
          }
          setLoading(false);
          //console.log(`Got all executions and this is run data ${JSON.stringify(runData)}`)
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };

      if (accessToken) {
        fetchData();
      }
      try {
        const response = await api.get(`/agent/prompts?agent_id=${agentId}`);

        // Create a new object for formData
        var newFormData = { ...formData };

        if (newFormData.basicConfig.assistantType == "FreeFlowing") {
          setFormData({
            ...formData,
            rulesConfig: {
              ...formData.rulesConfig,
              prompts: { ...response.data.data["task_1"] },
            },
          });
          newFormData = {
            ...formData,
            rulesConfig: {
              ...formData.rulesConfig,
              prompts: { ...response.data.data["task_1"] },
            },
          };
          //console.log(`Everything is free flowing ${JSON.stringify(newFormData)}`)
          //console.log(`Everything is free flowing ${JSON.stringify(newFormData)}`)
        } else {
          setFormData({
            ...formData,
            rulesConfig: {
              ...formData.rulesConfig,
              graph: { ...response.data.data["task_1"] },
            },
          });
          newFormData = {
            ...formData,
            rulesConfig: {
              ...formData.rulesConfig,
              graph: { ...response.data.data["task_1"] },
            },
          };
          //console.log(`ivr based ${JSON.stringify(newFormData)}`)
        }

        // Log the updated formData
        //console.log(`Updated formData ${JSON.stringify(newFormData)}`);
      } catch (error) {
        console.error("Error fetching prompt data:", error);
      }
      setLoading(false);
    };

    if (accessToken) {
      fetchPromptData();
    }
  }, [accessToken, agentId, userId]);

  const handleWebcallOpen = () => {
    Mixpanel.track("webcall_open", {
      agent: agent,
    });
    setOpenWebcall(true);
  };
  const handleWebcallClose = () => {
    setOpenWebcall(false);
  };

  const tabsData = [
    {
      name: "Agent Execution",
      component: <RunsTable accessToken={accessToken} />,
    },
    {
      name: "Analytics",
      component: <Analytics accessToken={accessToken} agentId={agentId} />,
    },
    {
      name: "Edit agent details",
      component: (
        <AgentFormStepper
          initialData={formData}
          isUpdate={true}
          agentId={agentId}
          accessToken={accessToken}
        />
      ),
    },
    {
      name: "Batch call",
      component: (
        <BatchCall agentId={agentId} accessToken={accessToken} user={user} />
      ),
    },
  ];
  const subHeaders = ["Agent Execution", "Analytics", "Batch Call"];
  return (
    <div className="h-full w-full bg-[06090F] text-white">
      {loading && (
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <Box sx={{ display: "flex" }}>
            <CircularProgress color="warning" />
          </Box>
        </div>
      )}
      <div className="flex justify-between items-center">
        <p className="text-xl md:text-4xl font-bold text-black">
          {agent.agent_name}
        </p>
        <div className="flex items-center text-white gap-x-2">
          <div
            className="cursor-pointer  "
            onClick={() => {
              toggleCallScreen();
            }}
          >
            <AddIcCall className="text-black mt-1" />
          </div>

          <div
            className="bg-black hidden text-white cursor-pointer md:flex items-center  rounded-md gap-x-2 py-0 px-2 md:px-3 py-3"
            onClick={() => {
              toggleInboundCall();
            }}
          >
            <p className="text-white text-xs md:text-base">
              Setup Inbound Calling
            </p>
          </div>
          <div
            className="bg-black text-white cursor-pointer flex items-center  rounded-md gap-x-2 py-0 px-2 md:px-3 py-3"
            onClick={() => {
              setShowEditAgent(!showEditAgent);
            }}
          >
            <p className="text-white text-xs md:text-base">Edit This Agent</p>
          </div>
          <div
            className="bg-black text-white cursor-pointer flex items-center  rounded-md gap-x-2 py-0 px-2 md:px-3 py-3"
            onClick={() => {
              togglePlayground();
            }}
          >
            <p className="text-white text-xs md:text-base">
              Testing Playground
            </p>
          </div>
        </div>
      </div>
      <div className="hidden md:block">
        <Subheader
          data={subHeaders}
          activeTab={activeSubHeader}
          setActiveTab={setActiveSubHeader}
        />
      </div>
      {isInboundCallOpen && (
        <InboundUrl
          toggleInboundUrl={toggleInboundCall}
          InboundUrl={inboundVoiceUrl}
        />
      )}
      {isPlaygroundOpen && (
        <div className="">
          <ChatScreen
            agentId={agentId}
            accessToken={accessToken}
            isPlaygroundOpen={isPlaygroundOpen}
            togglePlayground={togglePlayground}
            agentName={agent.agent_name}
          />
        </div>
      )}
      {isCallScreenOpen && (
        <div className="">
          <CallScreen
            toggleCallScreen={toggleCallScreen}
            agent={agent}
            accessToken={accessToken}
          />
        </div>
      )}
      {showEditAgent && (
        <AgentPrompt
          agentInfo={agent}
          setshowCreateAgent={setShowEditAgent}
          accessToken={accessToken}
          setIsLoading={setLoading}
          isLoading={loading}
        />
      )}

      {runData && runData.length > 0 ? (
        <div className="pt-8">
          <ExecutionTable
            data={runData}
            rawData={rawData}
            accessToken={accessToken}
          />
        </div>
      ) : (
        <div className="pt-8">
          <p className="text-black">No executions available</p>
        </div>
      )}
    </div>
  );
}

export default AgentDetails;

import React from "react";
import { useState, useEffect } from "react";
import createApiInstance from "../utils/api";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import CloseIcon from "@mui/icons-material/Close";
import VoicesAutocomplete from "./voicesAutocomplete";
import PromptEditor from "./PromptEditor";
import { getPrefilledTemplate } from "../pages/steps/BasicConfiguration";
import { convertToCreateAgentForm } from "../utils/utils";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Box,
  Typography,
  TextField,
  Button,
  Autocomplete,
  Checkbox,
} from "@mui/material";
import { CREATE_AGENT_FORM, getVoiceLabel } from "../utils/utils";
import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CircularProgress from "@mui/material/CircularProgress";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import simpleAgent from "../data/simpleAgent";
import InputComponent from "./InputComponent";
import BasicSettingsStep from "./AgentCreationSteps/BasicSettingsStep";
import LlmSettingsSteps from "./AgentCreationSteps/llmSettingsSteps";
import AsrSettingsStep from "./AgentCreationSteps/AsrSettingsStep";
import ConversationSettingsStep from "./AgentCreationSteps/ConversationSettingsStep";
import DropdownInput from "./DropDownInput";
import FollowUpSteps from "./AgentCreationSteps/FollowUpSteps";
import { convertToText } from "../utils/utils";
const questions = [
  "Choose an agent",
  "What is your Agent name?",
  "Build a prompt for your agent",
  "Follow up tasks",
  "Settings",
  "LLM Settings",
  "TTS Settings",
  "Conversation Nuances Settings",
];

const AgentPrompt = ({
  isOpen,
  session,
  accessToken,
  setshowCreateAgent,
  isLoading,
  setIsLoading,
  setAgentCreationCounter,
  agentInfo,
}) => {
  const [extraction, setExtraction] = useState(
    agentInfo ? agentInfo.extraction : ""
  );
  const [selectedAgentStyle, setSelectedAgentStyle] = useState();

  const [isExtraction, setisExtraction] = useState(false);
  const [isSummarization, setisSummarization] = useState(false);
  const [selectedTasks, setSelectedTasks] = useState([]);
  const conversationTask = agentInfo?.tasks?.find(
    (task) => task.task_type === "conversation"
  );
  const webHookTask = agentInfo?.tasks?.find(
    (task) => task.task_type === "webhook"
  );

  const [webhook, setWebHook] = useState(
    webHookTask?.tools_config?.api_tools?.webhookURL ?? ""
  );
  const [isWebhook, setisWebhook] = useState(
    webHookTask?.tools_config?.api_tools?.webhookURL ? true : false
  );
  const [delay, setDelay] = useState(
    conversationTask?.incremental_delay ?? 250
  );
  const [numberOfWords, setNumberOfWords] = useState(
    conversationTask?.number_of_words_for_interruption ?? 3
  );
  const [callHangupTime, setCallHangupTime] = useState(
    conversationTask?.hangup_after_silence ?? 20
  );
  const [maxTokens, setMaxTokens] = useState(
    conversationTask?.tools_config?.llm_agent?.max_tokens ?? 100
  );
  const [temperature, setTemperature] = useState(
    conversationTask?.tools_config?.llm_agent?.temperature ?? 0.7
  );
  let selectedLLMModel = null;
  const [llmModels, setLLMModels] = useState([]);
  const [selectModel, setSelectModel] = useState(
    llmModels ? llmModels[0] : null
  );
  const [Language, SetLanguage] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const [initialData, setInitialData] = useState(simpleAgent);
  const [formData, setFormData] = useState(initialData);

  const [currentStep, setCurrentStep] = useState(agentInfo ? 1 : 0);
  const [selectedOption, setSelectedOption] = useState(
    agentInfo ? "Custom Agent" : "Simple Agent"
  );
  const [agentName, setAgentname] = useState(
    agentInfo?.agent_name ? agentInfo.agent_name : "My Agent"
  );
  const [agentStyle, setAgentStyle] = useState("");
  const [primaryTask, setPrimaryTask] = useState("");

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [agentWelcomeMessage, setAgentWelcomeMessage] = useState(
    agentInfo?.agent_welcome_message
      ? agentInfo.agent_welcome_message
      : "Call Connected"
  );

  const [voices, setVoices] = useState();
  const [selectedVoice, setSelectedVoice] = useState(voices ? voices[0] : null);
  const [tasks, setTasks] = useState([]);
  const [extractionDetails, setExtractionDetails] = useState("");
  const promptData = getPrefilledTemplate("Lead Qualification");

  const [bufferSize, setBufferSize] = useState(
    conversationTask?.tools_config?.synthesizer?.buffer_size ?? 200
  );
  const [isLatencyOptimizatied, setisLatencyOptimizatied] = useState(
    agentInfo?.tasks?.[0].optimize_latency ?? true
  );
  if (agentInfo?.agent_prompts?.deserialized_prompts) {
    promptData.assistantDescription =
      agentInfo?.agent_prompts?.deserialized_prompts;
  }

  let agentDescription =
    "Give a short description of the agent (You are Zack, a sales agent for the company Sneakers Inc) \n Give a short description of the company (Sneakers Inc sells 2nd hand sneakers of leading brands like Nike, Adidas and New Balance) \n Give a short description of the primary intent (Your primary goal is to convince the user to buy sneakers from your company) \n Give a short brief of the major steps your agent has to follow (You have 3 primary tasks - To help find the right sneakers for the agent, to understand the shipping details, and to get them to confirm that they are interested in buying, following which you will send a whatsapp message with a purchase link) \n Give a short description of when the task is done (Your task is done either when the customer has agreed to buy a sneakers, or it is extremely clear that the user is not interested in talking to you or is annoyed by you)";
  const handleFormDataChange = (newData) => {
    setInitialData({ ...initialData, ...newData });
  };
  const handleEditorChange = (key, content) => {
    promptData.assistantDescription = content;
  };
  const api = createApiInstance(accessToken);
  const [agentInvocation, setAgentInvocation] = useState("Telephone");

  const handleOptionChange = (option) => {
    setSelectedOption(option);
  };
  const handleInputAgentName = (event) => {
    setAgentname(event.target.value);
  };
  const getPromptJsonFromRulesConfig = (
    prompts,
    is7BModel = false,
    isOpenAI
  ) => {
    var base_prompt = `
            ### Agent Description
         ${convertToText(prompts["assistantDescription"])} 
        `;
    if (
      prompts["exampleConversation"] != null &&
      prompts["exampleConversation"] != undefined &&
      prompts["exampleConversation"].length == 1 &&
      prompts["exampleConversation"]["children"]
    ) {
      alert(JSON.stringify(prompts["exampleConversation"]));
      base_prompt += `\n ### Steps
            ${convertToText(prompts["exampleConversation"])}`;
    }

    if (
      prompts["rules"] != null &&
      prompts["rules"] != undefined &&
      prompts["rules"].length != 0 &&
      prompts["rules"].length == 1 &&
      prompts["rules"]["children"]
    ) {
      base_prompt += `\n ### Rules
            ${convertToText(prompts["rules"])}`;
    }

    if (
      prompts["objections"] != null &&
      prompts["objections"] != undefined &&
      prompts["objections"].length != 0 &&
      prompts["objections"].length == 1 &&
      prompts["objections"]["children"]
    ) {
      base_prompt += `\n ### Objections
            ${convertToText(prompts["objections"])}`;
    }

    if (
      prompts["faqs"] != null &&
      prompts["faqs"] != undefined &&
      prompts["faqs"].length != 0 &&
      prompts["faqs"].length == 1 &&
      prompts["faqs"]["children"]
    ) {
      base_prompt += `\n ### FAQs
            ${convertToText(prompts["faqs"])}`;
    }

    base_prompt += `\n ### Note: \n 1. Just respond with one message at time. Always wait for user responses.`;

    console.log(`BASE PROMPT ${base_prompt}`);
    return base_prompt;
  };

  const agentStyleOptions = ["Free Flowing", "Intent Classification (IVR)"];
  const submit = async () => {
    try {
      setshowCreateAgent(false);
      if (isSubmitting) return;
      setIsSubmitting(true);
      setIsLoading(true);
      let updatedFormData;
      const PromptJson = {
        system_prompt: getPromptJsonFromRulesConfig(promptData),
      };
      if (selectedOption === "Simple Agent") {
        updatedFormData = {
          ...formData,
          agent_config: {
            ...formData.agent_config,
            agentInvocation: agentInvocation,
            latencyOptimizations: isLatencyOptimizatied,
            assistantType: "FreeFlowing",
            agent_name: agentName,
            tasks: formData.agent_config.tasks.map((task) => {
              if (task.task_type === "conversation") {
                return {
                  ...task,
                  tools_config: {
                    ...task.tools_config,
                    synthesizer: {
                      ...task.tools_config.synthesizer,
                      provider_config: {
                        ...task.tools_config.synthesizer.provider_config,
                        voice: selectedVoice.name,
                        voice_id: selectedVoice.id,
                      },
                    },
                  },
                };
              }
              return task;
            }),
          },
          agent_prompts: {
            ...formData.agent_prompts,
            serialized_prompts: JSON.stringify({ task_1: PromptJson }),
            deserialized_prompts: JSON.stringify({
              task_1: {
                assistantDescription: promptData.assistantDescription,
              },
            }),
          },
        };
      } else {
        updatedFormData = {
          ...formData,
          agent_config: {
            ...formData.agent_config,
            agent_name: agentName,
            assistantType: selectedAgentStyle,
            agent_welcome_message: agentWelcomeMessage,
            tasks: formData.agent_config.tasks.map((task) => {
              if (task.task_type === "conversation") {
                return {
                  ...task,
                  incremental_delay: delay,
                  number_of_words_for_interruption: numberOfWords,
                  hangup_after_silence: callHangupTime,
                  tools_config: {
                    ...task.tools_config,
                    llm_agent: {
                      ...task.tools_config.llm_agent,
                      family: selectModel.family,
                      request_json: selectModel.json_mode === "Yes",
                      streaming_model: selectModel.model,
                      classification_model: selectModel.model,
                      max_tokens: maxTokens,
                      temperature: temperature,
                    },
                    synthesizer: {
                      ...task.tools_config.synthesizer,
                      buffer_size: bufferSize,
                      provider_config: {
                        ...task.tools_config.synthesizer.provider_config,
                        voice: selectedVoice.name,
                        voice_id: selectedVoice.id,
                      },
                    },
                  },
                };
              }
              if (
                task.task_type === "webhook" &&
                selectedTasks.includes("webhook")
              ) {
                return {
                  ...task,
                  tools_config: {
                    ...task.tools_config,
                    api_tools: {
                      webhookURL: webhook,
                    },
                  },
                };
              }
              if (task.task_type === "extraction") {
                console.log(selectModel, "select model");
                if (selectedTasks.includes("extraction")) {
                  return {
                    ...task,
                    tools_config: {
                      ...task.tools_config,
                      llm_agent: {
                        ...task.tools_config.llm_agent,
                        extraction_details: extraction,
                        family: selectModel.family,
                        request_json: selectModel.json_mode === "Yes",
                        streaming_model: selectModel.model,
                        classification_model: selectModel.model,
                      },
                    },
                  };
                } else {
                  return {
                    ...task,
                    tools_config: {
                      ...task.tools_config,
                      llm_agent: {
                        ...task.tools_config.llm_agent,
                        family: selectModel.family,
                        request_json: selectModel.json_mode === "Yes",
                        streaming_model: selectModel.model,
                        classification_model: selectModel.model,
                      },
                    },
                  };
                }
              }
              return task;
            }),
          },
          agent_prompts: {
            ...formData.agent_prompts,
            serialized_prompts: JSON.stringify({ task_1: PromptJson }),
            deserialized_prompts: JSON.stringify({
              task_1: {
                assistantDescription: promptData.assistantDescription,
              },
            }),
          },
        };
      }
      let response;
      if (agentInfo) {
        response = await api.put(`/agent/${agentInfo.id}`, updatedFormData);
        navigate("/dashboard/my-agents");
      } else {
        response = await api.post("/agent", updatedFormData);
        setAgentCreationCounter((prev) => prev + 1);
      }
      setIsLoading(false);
      console.log(response);
      setIsSubmitting(false);
    } catch (e) {
      setIsSubmitting(false);
      setIsLoading(false);
      console.log(e);
      toast.error("Error creating agent", e);
    }
  };

  const handleNext = async () => {
    if (currentStep === 1) {
      if (agentName.length === 0) {
        alert("Agent Name cannot be empty");
        return;
      }
    }
    if (
      (selectedOption === "Custom Agent" &&
        currentStep === questions.length - 1) ||
      (selectedOption === "Simple Agent" && currentStep === 2)
    ) {
      await submit();
    } else if (currentStep < questions.length - 1) {
      setCurrentStep(currentStep + 1);
    }
  };
  const onClose = () => {
    setshowCreateAgent(false);
  };
  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleChange = (event, newValue) => {
    console.log(newValue);

    setSelectedVoice(newValue);
  };
  useEffect(() => {
    let isMounted = true;

    const fetchModels = async () => {
      if (webHookTask?.tools_config?.api_tools?.webhookURL !== null) {
        setSelectedTasks(["webhook", "extraction"]);
      }
      try {
        if (isMounted) {
          const response = await api.get("/get_all_voices");
          setVoices(response.data.voices);
          if (
            conversationTask?.tools_config?.synthesizer?.provider_config
              ?.voice_id !== undefined
          ) {
            const voiceIndex = response.data.voices.findIndex(
              (voice) =>
                voice.id ===
                conversationTask?.tools_config?.synthesizer?.provider_config
                  ?.voice_id
            );
            setSelectedVoice(response.data.voices[voiceIndex]);
          } else {
            setSelectedVoice(response.data.voices[0]);
          }
          setSelectModel(response.data.llmModels[0]);
          setLLMModels(response.data.llmModels);
        }
      } catch (error) {
        if (isMounted) {
          console.error("Error fetching agents making loading false:", error);
        }
      } finally {
        if (isMounted) {
          console.log(selectedVoice, "selected voice");
          console.log("Finally block");
        }
      }
    };

    if (accessToken) {
      fetchModels();
    }

    return () => {
      isMounted = false;
    };
  }, [accessToken]);
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-white bg-opacity-50 flex z-50 flex-col rounded-lg ">
      <div className="rounded-lg pb-4 shadow-lg w-full md:w-[52%]   bg-white relative  ">
        <div className="flex justify-between items-center px-3 bg-black text-white  py-4 rounded-lg ">
          <div
            className="cursor-pointer"
            onClick={() => {
              handleBack();
            }}
          >
            <ArrowBackIosNewIcon />
          </div>

          <div>
            <h2>{questions[currentStep]}</h2>
          </div>
          <div
            className="cursor-pointer"
            onClick={() => {
              onClose();
            }}
          >
            <CloseIcon />
          </div>
        </div>
        {/* step 1 */}
        <div className={currentStep === 0 ? "visible" : "hidden"}>
          <div className="flex justify-center items-center gap-x-6 px-3 py-6 text-black">
            <div
              className={
                selectedOption === "Simple Agent"
                  ? " rounded xl  border-2 border-solid border-black  text-left px-3 py-3 relative mb-3 cursor-pointer"
                  : " border-2 border-solid border-black border-gray-400      rounded xl text-left px-3 py-3 mb-3 relative  cursor-pointer "
              }
              onClick={() => {
                handleOptionChange("Simple Agent");
              }}
            >
              <div className="flex justify-between items-center py-1">
                <div className="flex  justify-between items-center gap-x-2">
                  <p className=" font-bold text-lg ">Simple Agent</p>
                  <p className="text-md">(Recommeneded)</p>
                </div>

                <div
                  className={
                    selectedOption === "Simple Agent" ? "visible" : "hidden"
                  }
                >
                  <CheckCircleIcon />
                </div>
              </div>

              <p className="text-xs">
                Get started with Outcall quickly and easily. This streamlined
                mode is ideal for most users, requiring minimal setup time and
                technical knowledge. If you're unsure which option to choose, go
                with simple Agent
              </p>
            </div>
            <div
              className={
                selectedOption === "Custom Agent"
                  ? "rounded xl  hidden md:block  text-left border-2 border-solid border-black px-3 py-3 mb-3 relative cursor-pointer"
                  : " border-2 border-solid  hidden md:block border-gray-300 border-opacity-80 rounded py-3 py-3 text-left mb-3  px-3 relative  cursor-pointer "
              }
              onClick={() => {
                handleOptionChange("Custom Agent");
              }}
            >
              <div className=" flex justify-between items-center py-1 ">
                <div>
                  <p className="font-bold text-lg ">Custom Agent</p>
                </div>

                <div>
                  <CheckCircleIcon
                    className={
                      selectedOption === "Custom Agent" ? "visible" : "hidden "
                    }
                  />
                </div>
              </div>
              <p className="text-xs">
                Expert Mode: For advanced users only. Access to advanced
                settings and parameters. Ability to customize prompts and model
                behavior. Suited for developers and AI enthusiasts. Allows for
                in-depth experimentation and fine-tuning.
              </p>
            </div>
          </div>
        </div>
        <div
          className={selectedOption === "Simple Agent" ? "visible" : "hidden"}
        >
          <div className={currentStep === 1 ? "visible " : "hidden"}>
            <div className="flex flex-col gap-y-4  justify-center items-center w-full py-6 pt-9">
              <div className="w-full flex justify-center items-center relative pt-3">
                <label className="absolute  -top-[21%] left-[20%] text-sm text-black ">
                  Agent Name
                </label>
                <input
                  type="text"
                  value={agentName}
                  className="border-2 focus:none border-solid border-black p-4 bg-white w-3/5  rounded-md text-left px-3 relative text-black"
                  placeholder="My Agent"
                  onChange={handleInputAgentName}
                />
              </div>
              <VoicesAutocomplete
                handleChange={handleChange}
                voices={voices}
                getVoiceLabel={getVoiceLabel}
                selectedOption={selectedVoice}
              />
            </div>
          </div>
          <div
            className={
              currentStep === 2 ? "visible w-full  text-black" : "hidden"
            }
          >
            <div className="flex justify-center items-center w-full h-[22rem] py-6 overflow-visible ">
              <PromptEditor
                className="w-full "
                identifier="assistantDescription"
                helperText={agentDescription}
                onEditorDataChange={handleEditorChange}
                value={promptData.assistantDescription}
              />
            </div>
          </div>
        </div>
        <div
          className={
            selectedOption === "Custom Agent" && currentStep > 0
              ? "visible"
              : "hidden"
          }
        >
          <div
            className={
              currentStep === 1 ? "flex flex-col gap-y-7 pt-8 pb-6" : "hidden"
            }
          >
            <InputComponent
              label="Agent Name"
              setInputValue={handleInputAgentName}
              inputValue={agentName}
            />
            <DropdownInput
              label="Agent style"
              setSelectedOption={setSelectedAgentStyle}
              options={agentStyleOptions}
            />
            <DropdownInput
              label="Primary Task"
              options={["Example one", "Other"]}
              setSelectedOption={setPrimaryTask}
            />
            <InputComponent
              label="Welcome Message"
              inputValue={agentWelcomeMessage}
              setInputValue={(e) => setAgentWelcomeMessage(e.target.value)}
            />
            <label className="flex justify-center items-center space-x-2  cursor-pointer">
              <input
                type="checkbox"
                checked={isLatencyOptimizatied}
                onChange={(e) => {
                  setisLatencyOptimizatied(e.target.checked);
                }}
                className="hidden"
              />
              <span
                className={`w-6 h-6 border-2 rounded-md flex items-center justify-center 
                    ${
                      isLatencyOptimizatied
                        ? "bg-black   border-[#7F56D9]"
                        : "bg-black border-[#7F56D9] border-[1px] border-solid"
                    }`}
              >
                {isLatencyOptimizatied && (
                  <svg
                    className="w-4 h-4 text-white"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 13l4 4L19 7"
                    ></path>
                  </svg>
                )}
              </span>
              <span className="text-black">Enable Latency Optimizations</span>
            </label>
            <div className="flex justify-center items-center">
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  value={agentInvocation}
                  name="radio-buttons-group"
                  onChange={(event) => setAgentInvocation(event.target.value)}
                  sx={{
                    "& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)":
                      {
                        color: "black",
                      },
                    "& .MuiSvgIcon-root + .MuiSvgIcon-root": {
                      color: "black",
                    },
                  }}
                >
                  <div className="flex">
                    <FormControlLabel
                      sx={{
                        "&.Mui-checked": {
                          color: "black",
                        },
                        "& .MuiFormControlLabel-label": {
                          color: "black",
                        },
                      }}
                      value="Telephone"
                      control={<Radio />}
                      label="Telephone"
                    />
                    <FormControlLabel
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          color: "black",
                        },
                      }}
                      value="WebSocket"
                      control={<Radio />}
                      label="WebSocket"
                    />
                  </div>
                </RadioGroup>
              </FormControl>
            </div>
          </div>
          <div>
            <div
              className={
                currentStep === 2
                  ? "flex justify-center items-center w-full h-[22rem] py-6 overflow-visible visible"
                  : "hidden"
              }
            >
              <PromptEditor
                className="w-full "
                identifier="assistantDescription"
                helperText={agentDescription}
                onEditorDataChange={handleEditorChange}
                value={promptData.assistantDescription}
              />
            </div>
          </div>
          <div
            className={currentStep === 3 ? "flex flex-col gap-y-7  " : "hidden"}
          >
            <FollowUpSteps
              isExtraction={isExtraction}
              setisExtraction={setisExtraction}
              isSummarization={isSummarization}
              setisSummarization={setisSummarization}
              isWebhook={isWebhook}
              setisWebhook={setisWebhook}
              isLatencyOptimizatied={isLatencyOptimizatied}
              setisLatencyOptimizatied={setisLatencyOptimizatied}
              selectedTasks={selectedTasks}
              setSelectedTasks={setSelectedTasks}
              extraction={extraction}
              setExtraction={setExtraction}
              webhook={webhook}
              setWebHook={setWebHook}
            />
          </div>
          <div className={currentStep === 4 ? "visible " : "hidden"}>
            <BasicSettingsStep
              setLLMModels={setLLMModels}
              llmModels={llmModels}
              voices={voices}
              handleChange={handleChange}
              getVoiceLabel={getVoiceLabel}
              selectModel={selectModel}
              setSelectModel={setSelectModel}
              SetLanguage={SetLanguage}
              Language={Language}
              selectedVoice={selectedVoice}
            />
          </div>
          <div className={currentStep === 5 ? "visible " : "hidden"}>
            <LlmSettingsSteps
              maxTokens={maxTokens}
              setMaxTokens={setMaxTokens}
              temperature={temperature}
              setTemperature={setTemperature}
            />
          </div>

          <div className={currentStep === 6 ? "visible  " : "hidden"}>
            <div className="flex flex-col  gap-y-7 pt-8 pb-6 ">
              <InputComponent
                label="Buffer Size"
                inputValue={bufferSize}
                setInputValue={(e) => {
                  setBufferSize(e.target.value);
                }}
                type="number"
              />
            </div>
          </div>
          <div className={currentStep === 7 ? "visible  " : "hidden"}>
            <ConversationSettingsStep
              delay={delay}
              setDelay={setDelay}
              numberOfWords={numberOfWords}
              callHangupTime={callHangupTime}
              setNumberOfWords={setNumberOfWords}
              setCallHangupTime={setCallHangupTime}
            />
          </div>
        </div>
        <div className="flex justify-center gap-x-20 ">
          <button
            className=" bg-black text-white border-none px-4 cursor-pointer  py-2 rounded-md  "
            onClick={handleNext}
          >
            {(selectedOption === "Custom Agent" &&
              currentStep === questions.length - 1) ||
            (selectedOption === "Simple Agent" && currentStep === 2)
              ? "Finish"
              : "Next"}
          </button>
        </div>
      </div>
    </div>
  );
};
export default AgentPrompt;

import React from "react";
import { useState, useRef, useEffect } from "react";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { MessageBox } from "react-chat-elements";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import SendIcon from "@mui/icons-material/Send";
import "react-chat-elements/dist/main.css";
import GraphicEqIcon from "@mui/icons-material/GraphicEq";
import CloseIcon from "@mui/icons-material/Close";
import "../custom-chat.css";
import { base64ToBlob } from "../utils/utils";
import VideocamIcon from "@mui/icons-material/Videocam";
import StopCircleIcon from "@mui/icons-material/StopCircle";

const ChatScreen = ({
  togglePlayground,
  agentName,
  agentId,
  accessToken,
  isPlaygroundOpen,
}) => {
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [recording, setRecording] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [wsConnected, setWsConnected] = useState(true);
  const [durations, setDurations] = useState([]);
  const [isPlaying, setIsPlaying] = useState([]);
  const ws = useRef(null);
  const mediaRecorder = useRef(null);
  const audioRefs = useRef([]);
  const audioChunks = useRef([]);
  const handleClickOutside = (e) => {
    if (e.target.closest(".phone-modal-content") === null) {
      togglePlayground();
    }
  };
  let incomingMessage = "";
  let i = 0;
  function handleIncomingMessage(receivedMessage) {
    //console.log(`Data ${receivedMessage.type} JSON Sata ${JSON.stringify(receivedMessage)}`)

    if (receivedMessage.type == "text") {
      if (receivedMessage.data == "<beginning_of_stream>") {
        setIsTyping(true);
        incomingMessage = "";
      } else if (receivedMessage.data == "<end_of_stream>") {
        if (incomingMessage.length != 0) {
          setIsTyping(false);
          //console.log(`Before pushing ${incomingMessage}`)
          setMessages((prev) => [
            ...prev,
            {
              type: "text",
              position: "left",
              text: incomingMessage,
              title: "AI",
            },
          ]);
        } else {
          console.log(`incoming message is null but still got end of stream`);
        }
      } else {
        incomingMessage += " " + receivedMessage.data;
      }
    } else if (receivedMessage.type == "audio") {
      //console.log(`Got audio message`)

      const base64Audio = receivedMessage.data;
      //console.log(`Base64 audio ${base64Audio}`)
      //const base64AudioData = base64Audio //`data:audio/wav;base64,${base64Audio}`;
      const base64AudioData = `${base64Audio}`;
      const audioBlob = base64ToBlob(base64AudioData, "audio/wav");
      const audioUrl = URL.createObjectURL(audioBlob);

      const audioMessage = {
        type: "audio",
        position: "left",
        title: "AI",
        data: {
          audioURL: audioUrl,
          audioType: "audio/wav",
        },
      };
      setMessages((prev) => [...prev, audioMessage]);
      setIsTyping(false);
    } else if (receivedMessage.type == "close") {
      alert("Insufficient funds");
    }
  }

  useEffect(() => {
    // messages.forEach((m) => {
    //     console.log(`Logging mesages ${JSON.stringify(m)}`)
    // })
    // console.log("Hello " + i)
    i += 1;
    //console.log("Before websocket connection")

    if (!ws.current) {
      //console.log("Starting websocket connection")
      ws.current = new WebSocket(
        `${process.env.REACT_APP_WEBSOCKET_ENDPOINT}/${agentId}?user_agent=dashboard&auth_token=${accessToken}`
      );

      ws.current.addEventListener("open", () => {
        console.log("WebSocket Connected");
      });

      ws.current.addEventListener("message", (event) => {
        const receivedMessage = JSON.parse(event.data);
        handleIncomingMessage(receivedMessage);
      });

      ws.current.addEventListener("close", () => {
        console.log("WebSocket Disconnected");
        ws.current = null;
      });

      ws.current.addEventListener =
        ("error",
        (err) => {
          console.error("WebSocket Error:", err);
          ws.current.close();
          ws.current = null;
        });
    }

    if (!isPlaygroundOpen) {
      ws.current.close();
      console.error("Closing websocket");
    }
  });

  const sendMessage = () => {
    if (message !== "") {
      const newMessage = {
        type: "text",
        text: message,
        position: "right",
        title: "You",
      };
      setMessages((prevMessages) => [...prevMessages, newMessage]);
      ws.current.send(JSON.stringify({ type: "text", data: message }));
      setMessage("");
    }
  };
  const startRecording = async () => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorder.current = new MediaRecorder(stream, { type: "audio/webm" });
      mediaRecorder.current.ondataavailable = (event) =>
        audioChunks.current.push(event.data);
      mediaRecorder.current.onstop = sendAudioMessage;
      audioChunks.current = [];
      mediaRecorder.current.start();
      setRecording(true);
    }
  };

  const stopRecording = () => {
    if (mediaRecorder.current) {
      mediaRecorder.current.stop();
      setRecording(false);
    }
  };

  const sendAudioMessage = () => {
    const audioBlob = new Blob(audioChunks.current, { type: "audio/webm" });
    //console.log(`Sending audio message`)

    const reader = new FileReader();
    reader.readAsDataURL(audioBlob);
    reader.onloadend = () => {
      var base64AudioMessage = reader.result;
      base64AudioMessage = base64AudioMessage.split(",")[1];
      //console.log(`senfing ${base64AudioMessage}`);
      ws.current.send(
        JSON.stringify({ type: "audio", data: base64AudioMessage })
      );
    };
    const audioUrl = URL.createObjectURL(audioBlob);
    const audioMessage = {
      type: "audio",
      position: "right",
      title: "You",
      data: {
        audioURL: audioUrl,
      },
    };
    setMessages((prev) => [...prev, audioMessage]);
    setIsTyping(true);
  };
  useEffect(() => {
    const updatedDurations = messages.map(() => "00:00");
    const updatedIsPlaying = messages.map(() => false);
    setDurations(updatedDurations);
    setIsPlaying(updatedIsPlaying);
  }, [messages]);

  const handlePlayPause = (index) => {
    const audio = audioRefs.current[index];
    const updatedIsPlaying = [...isPlaying];

    if (audio.paused) {
      audio.play();
      updatedIsPlaying[index] = true;
    } else {
      audio.pause();
      updatedIsPlaying[index] = false;
    }

    setIsPlaying(updatedIsPlaying);
  };

  const handleLoadedMetadata = (index) => {
    const audio = audioRefs.current[index];
    const updatedDurations = [...durations];
    updatedDurations[index] = formatTime(audio.duration);
    setDurations(updatedDurations);
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes < 10 ? "0" : ""}${minutes}:${
      seconds < 10 ? "0" : ""
    }${seconds}`;
  };
  return (
    <div
      className="fixed inset-0 flex items-center z-40 justify-center bg-black bg-opacity-50 flex flex-col rounded-lg "
      onClick={handleClickOutside}
    >
      <div className="flex justify-center items-center h-screen relative">
        <div className="cursor-pointer absolute  z-50 top-[10%]">
          <div
            className="bg-red-500 text-white cursor-pointer flex items-center  rounded-md gap-x-2 py-0 px-2 md:px-3 py-3"
            onClick={() => {
              togglePlayground();
            }}
          >
            <p>Close</p>
          </div>
        </div>
        <div className="phone-modal-content w-80 h-[500px] md:h-[667px] pt-6 bg-[#0D151C]  border-white border-[1px] border-solid rounded-[40px] shadow-lg overflow-hidden flex flex-col relative">
          <div className="flex p-2 bg-gray-900  z-40 absolute h-content top-0 w-full">
            <div className="flex justify-between items-center pt-7 px-1 mt-1 w-full  ">
              <div className="flex  ">
                <ArrowBackIosIcon className="h-6 w-6" />
              </div>
              <div className=" flex flex-col justify-center items-center py-0.5">
                <img
                  src="/femaleAvatar.png"
                  alt="Avatar"
                  className="h-10 w-10 rounded-full"
                />
                <p className="text-xs">{agentName}</p>
              </div>
              <div>
                <VideocamIcon className="h-6 w-6" />
              </div>
            </div>
          </div>
          <div className="flex-1 overflow-y-auto space-y-4 px-3 absolute bottom-3 w-full overflow-y-visible">
            {messages.map((message, index) => (
              <div
                key={message.id}
                className={`flex ${
                  index % 2 === 0 ? "justify-end" : "justify-start"
                }`}
              >
                {message.type === "audio" ? (
                  <div className="flex items-center space-x-2 p-2 bg-gray-700 rounded-xl">
                    <button
                      onClick={() => handlePlayPause(index)}
                      className="bg-transparent border-none outline-none focus:outline-none"
                    >
                      {isPlaying[index] ? (
                        <StopCircleIcon />
                      ) : (
                        <PlayArrowIcon />
                      )}
                    </button>
                    <audio
                      ref={(el) => (audioRefs.current[index] = el)}
                      className="w-full bg-transparent border-none outline-none text-white hidden"
                      onLoadedMetadata={() => handleLoadedMetadata(index)}
                    >
                      <source src={message.data.audioURL} type="audio/mpeg" />
                      Your browser does not support the audio element.
                    </audio>
                    <div className="flex-1 bg-gray-600 rounded-full h-1">
                      <div
                        className="bg-white h-1 rounded-full"
                        style={{ width: "50%" }}
                      ></div>
                    </div>
                    <div className="text-white text-xs">{durations[index]}</div>
                  </div>
                ) : (
                  <div
                    className={`max-w-xs p-3  rounded-full ${
                      index % 2 === 0
                        ? "bg-blue-500 text-white pr-2 "
                        : "bg-gray-800 text-gray-200 pl-4 "
                    }`}
                  >
                    <p className="text-sm"> {message.text}</p>
                  </div>
                )}
              </div>
            ))}
            <div>
              {recording ? (
                <div
                  className="flex justify-center items-center"
                  onClick={recording ? stopRecording : startRecording}
                >
                  <StopCircleIcon
                    className="text-red-600 cursor-pointer "
                    fontSize="large"
                  />
                </div>
              ) : (
                <div className="p-2 border-t border-gray-300 flex items-center relative mb-2">
                  <input
                    type="text"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder="Type a message..."
                    onKeyPress={(e) => e.key === "Enter" && sendMessage()}
                    className="flex-1 p-2  border rounded-full outline-none focus:ring-2 focus:ring-blue-500"
                  />
                  <div className="absolute   right-3">
                    {message.length === 0 ? (
                      <GraphicEqIcon
                        onClick={recording ? stopRecording : startRecording}
                        className="text-red-600"
                      />
                    ) : (
                      <ArrowCircleUpIcon
                        onClick={sendMessage}
                        className="text-white bg-blue-400 rounded-full"
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatScreen;
{
  /* <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 flex flex-col rounded-lg ">
      <div className="rounded-lg pb-4 shadow-lg w-[40%] h-1/3   bg-[#101829] relative  ">
        <div className="flex justify-between items-center px-3  bg-[#0A0F1A] py-4 rounded-lg ">
          <div>
            <h3>Trail chat</h3>
          </div>
          <div
            className="cursor-pointer"
            onClick={() => {
              togglePlayground();
            }}
          >
            <CloseIcon />
          </div>
        </div>
        <div className="absolute bottom-10 right-0">
          <MessageBox
            position={"right"}
            type={"text"}
            text={"I need assistance with my order."}
            className="custom-message-left"
          />
        </div>
      </div>
    </div> */
}

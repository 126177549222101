import { ArrowForwardIos } from "@mui/icons-material";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
const ExecutionTable = ({ data, user, rawData }) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkMobile();
  }, []);
  const navigate = useNavigate();
  let Headers = isMobile
    ? ["Run Data", "Total Cost($)", "Run Time", " "]
    : ["Run ID", "Run Data", "Total Cost($)", "Run Time", " "];

  const rows = data.map((obj) => Object.values(obj));

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 7;
  const totalPages = Math.ceil(rows.length / itemsPerPage);

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const paginatedRows = rows.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <div className="overflow-x-auto">
      <table className="table-auto min-w-full border-collapse rounded-xl">
        <thead className="bg-white text-black border-t border-b border-[#101828] border-solid border-[1px] rounded-xl">
          <tr>
            {Headers.map((header, index) => (
              <th key={index} className="border-b px-2 md:px-6 py-4 text-left">
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {paginatedRows.map((row, rowIndex) => (
            <tr
              key={rowIndex}
              onClick={() => {
                navigate("/dashboard/agent/run-details", {
                  state: {
                    runDetails:
                      rawData[(currentPage - 1) * itemsPerPage + rowIndex],
                  },
                });
              }}
              className="border-b-2 border-solid  border-[#1D2939] rounded-xl"
            >
              {row
                .slice(isMobile ? 1 : 0, isMobile ? 4 : row.length)
                .map((cell, cellIndex) => (
                  <td
                    className="border-b text-black px-2 md:px-6 py-4 text-left"
                    key={cellIndex}
                  >
                    {cell}
                  </td>
                ))}
              <td className="px-6 py-4 text-left flex items-center justify-center text-black cursor-pointer">
                <div className="flex justify-center items-center">
                  <p className="">View</p>
                  <ArrowForwardIos fontSize="small" />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="bg-white text-black py-4 px-6 border-t border-b border-[#101828] border-solid border-[1px] rounded-xl flex justify-between items-center">
        <div>
          Showing {itemsPerPage <= data.length ? itemsPerPage : data.length} out
          of {data.length}
        </div>
        <div className="flex justify-between ">
          <div
            onClick={handlePrevPage}
            className={` cursor-pointer py-1 rounded-md ${
              currentPage === 1 && "opacity-50 cursor-not-allowed"
            }`}
            disabled={currentPage === 1}
          >
            <ArrowBackIosIcon className="text-sm pt-1" />
          </div>
          <div className="px-2 text-xl">{currentPage}</div>
          <div
            onClick={handleNextPage}
            className={`  py-1 rounded-md cursor-pointer ${
              currentPage === totalPages && "opacity-50 cursor-not-allowed"
            }`}
            disabled={currentPage === totalPages}
          >
            <ArrowForwardIosIcon className="text-sm pt-1" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExecutionTable;

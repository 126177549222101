import React from "react";
import InputComponent from "../InputComponent";
import { useState } from "react";
import DropdownInput from "../DropDownInput";
import VoicesAutocomplete from "../voicesAutocomplete";
import { Autocomplete, TextField, Paper } from "@mui/material";
const BasicSettingsStep = ({
  handleChange,
  voices,
  getVoiceLabel,
  selectModel,
  setSelectModel,
  Language,
  SetLanguage,
  llmModels,
  setLLMModels,
  selectedVoice,
}) => {
  return (
    <div className="flex flex-col gap-y-7 pt-8 pb-6">
      <div className="w-full flex justify-center items-center relative">
        <VoicesAutocomplete
          handleChange={handleChange}
          voices={voices}
          getVoiceLabel={getVoiceLabel}
          selectedOption={selectedVoice}
        />
      </div>
      <div className="w-full flex justify-center items-center relative">
        <Autocomplete
          className="pt-2 w-3/5 "
          PaperComponent={({ children }) => (
            <Paper
              style={{
                background: "black",
                color: "white",
              }}
            >
              {children}
            </Paper>
          )}
          sx={{
            color: "white",
            "& .MuiAutocomplete-inputRoot": {
              backgroundColor: "white",
              color: "black",
              padding: "10px",
              border: "2px solid black",
              borderRadius: "8px",
              "& .MuiInputBase-input": {
                color: "black",

                padding: 0,
              },
              "&::placeholder": {
                color: "black",
              },
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                border: "2px solid black",
              },
              "&:hover fieldset": {
                border: "2px solid black",
              },
              "&.Mui-focused fieldset": {
                border: "2px solid black",
              },
            },
            "& .MuiFormLabel-root": {
              color: "black",
            },
            "& .MuiInputLabel-root": {
              color: "black",
              "&.Mui-focused": {
                color: "black",
              },
            },

            "& .MuiAutocomplete-groupUl ": {
              color: "black",
            },
            "& .MuiAutocomplete-listbox": {
              backgroundColor: "black",

              "& .MuiAutocomplete-option": {
                color: "black",
              },
            },
            "& .MuiAutocomplete-popupIndicator": {
              color: "black",
            },
            "& .MuiAutocomplete-clearIndicator": {
              color: "black",
            },
          }}
          options={llmModels}
          value={
            selectModel ??
            (llmModels && llmModels.length > 0 ? llmModels[0] : null)
          }
          defaultValue={llmModels?.llmModels?.[0]}
          name="Model"
          getOptionLabel={(option) => option.display_name}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          filterOptions={(options, { inputValue }) => {
            return options.filter(
              (option) =>
                option.display_name
                  .toLowerCase()
                  .includes(inputValue.toLowerCase()) ||
                option.family.toLowerCase().includes(inputValue.toLowerCase())
            );
          }}
          renderInput={(params) => (
            <TextField {...params} label="Select Model" variant="standard" />
          )}
          onChange={(event, newValue) => {
            setSelectModel(newValue);
          }}
          fullWidth
          margin="normal"
        />
      </div>
      <DropdownInput
        label="Language"
        inputValue={Language}
        setSelectedOption={SetLanguage}
        options={["en", "hi", "es", "fr", "it", "pt-BR"]}
      />
    </div>
  );
};

export default BasicSettingsStep;

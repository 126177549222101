import React from "react";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Info from "@mui/icons-material/Info";
import { Mixpanel } from "../utils/mixpanel";
import createApiInstance from "../utils/api";
import { CopyAll } from "@mui/icons-material";

function InboundUrl({ toggleInboundUrl, InboundUrl }) {
  const [isCopied, setIsCopied] = useState(false);
  const handleCopy = () => {
    navigator.clipboard.writeText(InboundUrl);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 500);
  };
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 flex flex-col rounded-lg ">
      <div className="rounded-lg pb-4 shadow-lg w-[40%]     bg-white relative  ">
        <div className="flex justify-between items-center px-3  bg-black py-4 rounded-lg ">
          <div>
            <h3>Inbound Call</h3>
          </div>
          <div
            className="cursor-pointer"
            onClick={() => {
              toggleInboundUrl();
            }}
          >
            <CloseIcon />
          </div>
        </div>
        <div className=" flex flex-col justify-center items-center pt-4 w-full gap-y-3  mx-auto ">
          <div className="bg-white  border-2 border-solid border-black p-2 text-gray-300 text-sm flex items-center  gap-x-2 w-[85%]">
            <Info />
            <p className="text-black">
              You can use the following URL to route incoming calls to this
              agent.
            </p>
          </div>
          <div className="bg-white p-2 text-black  border-2 border-solid border-black text-sm flex items-center  gap-x-2 w-[85%]">
            <p>{InboundUrl}</p>
            <div
              className={`cursor-pointer transition-all duration-300 ease-in-out ${
                isCopied ? "scale-110 text-green-500" : ""
              }`}
              onClick={handleCopy}
            >
              <CopyAll />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InboundUrl;

import React, { useState, useEffect } from "react";
import {
  CircularProgress,
  Divider,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Slider,
  Typography,
  Grid,
  Box,
  Autocomplete,
  Button,
  Dialog,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
} from "@mui/material";
import VoiceLab from "../models/VoiceLab";
import { getVoiceLabel } from "../../utils/utils";
import { renderTooltip } from "../../components/CustomTooltip";
import { Mixpanel } from "../../utils/mixpanel";
import createApiInstance from "../../utils/api";

function ModelSettings({
  formData,
  onFormDataChange,
  llmModels,
  voices,
  setVoices,
  initiallySelectedVoice,
  initiallySelectedModel,
  accessToken,
  isUpdate,
}) {
  var val = null;
  var key = null;
  const [selectedVoice, setSelectedVoice] = useState(initiallySelectedVoice);
  const [selectedModel, setSelectedModel] = useState(initiallySelectedModel);
  const [availableLLMModels, setAvailableLLMModels] = useState(llmModels);
  const [isLoading, setIsLoading] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [currentPrice, setCurrentPrice] = useState("0.1");
  const api = createApiInstance(accessToken);

  let selectedComponents = {
    asr: "deepgram",
    llm: selectedModel,
    tts: selectedVoice,
  };

  const handleDialogOpen = () => {
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const fetchPrice = async (params, pageLoad = false) => {
    try {
      if (pageLoad === true) {
        setIsLoading(true);
      }
      const response = await api.get(
        `/get_current_price?asr=${formData.modelsConfig.asrConfig.model}&llm=${params["llm"].model}&llm_family=${params["llm"].family}&tts=${params["tts"].provider}&voice=${params["tts"].id}`
      );
      if (response.status === 200) {
        setCurrentPrice(response.data.data);
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error fetching prices:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchPrice(selectedComponents, true);
  }, []);

  useEffect(() => {
    if (formData.basicConfig.assistantType == "IVR") {
      let filteredLLMModels = llmModels.filter(
        (model) => model.json_mode == "Yes"
      );
      setAvailableLLMModels([...filteredLLMModels]);
    }
  }, [formData]);

  const handleChange = (type, event) => {
    let toChangePair = {};
    let conf = type + "Config";

    if (event?.target?.name == undefined || event?.target?.name == null) {
      if (type == "asr") {
        val = event;
        key = "language";
        toChangePair = { [key]: val };
      } else if (type == "tts") {
        val = event.name;
        key = "voice";
        setSelectedVoice(event);
        toChangePair = { [key]: val };
      } else {
        val = event.model;
        key = "model";
        setSelectedModel(event);
        toChangePair = { [key]: val, family: event.family };
      }

      if (["llm", "tts", "asr"].includes(type)) {
        selectedComponents[type] = event;
        fetchPrice(selectedComponents);
      }
    } else {
      val = event.target.value;
      key = event.target.name;
      toChangePair = { [key]: val };
    }

    Mixpanel.track("advance_setting", {
      name: key,
      val: val,
    });

    onFormDataChange({
      ...formData,
      modelsConfig: {
        ...formData.modelsConfig,
        [conf]: {
          ...formData.modelsConfig[conf],
          ...toChangePair,
        },
      },
    });
  };

  const marks = [
    { value: 0, label: "Professional" },
    { value: 1, label: "Highly Creative" },
  ];

  const marginLeftForButton = "22%";
  const positionForVoice = "relative";
  const languages = ["en", "hi", "es", "fr", "it", "pt-BR"];
  const asrModels = ["whisper", "deepgram"];

  const [callHangupViaPrompt, setCallHangupViaPrompt] = useState(
    formData.modelsConfig.nuancesConfig.hangUpAfterLLMCall.toString()
  );

  const handleRadioChange = (event) => {
    Mixpanel.track("callHangupViaPrompt", {
      item: event.target.value,
    });

    setCallHangupViaPrompt(event.target.value);
    handleChange("nuances", event);
  };

  return (
    <form>
      {isLoading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Grid container spacing={2}>
            {/* LLM Settings */}
            <Grid item xs={2} alignContent={"left"}>
              <Typography variant="h6"> Basic Settings</Typography>
            </Grid>
            <Grid item xs={8} md={8} sx={{ textAlign: "center" }}>
              <FormControl
                sx={{ alignItems: "left", flexDirection: "row", width: "60%" }}
              >
                <Autocomplete
                  options={availableLLMModels}
                  defaultValue={selectedModel}
                  name="Model"
                  getOptionLabel={(option) => option.display_name}
                  filterOptions={(options, { inputValue }) => {
                    return options.filter(
                      (option) =>
                        option.display_name
                          .toLowerCase()
                          .includes(inputValue.toLowerCase()) ||
                        option.family
                          .toLowerCase()
                          .includes(inputValue.toLowerCase())
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Model"
                      variant="standard"
                    />
                  )}
                  onChange={(event, newValue) => handleChange("llm", newValue)}
                  fullWidth
                  margin="normal"
                />

                {renderTooltip(
                  "This is the LLM Model your user will chat with. gpt models are by OpenAI other are open source. GPT models are more expensive."
                )}
              </FormControl>

              <FormControl
                sx={{ alignItems: "left", flexDirection: "row", width: "60%" }}
              >
                <Autocomplete
                  options={languages}
                  name="language"
                  getOptionLabel={(option) => option}
                  value={formData.modelsConfig.asrConfig.language}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Language"
                      margin="normal"
                      variant="standard"
                    />
                  )}
                  onChange={(event, newValue) => handleChange("asr", newValue)}
                  fullWidth
                />

                {renderTooltip(
                  "This is the primary language of your agent. Only GPT models have Multilingual capabilities."
                )}
              </FormControl>

              <Box
                sx={{ display: "flex", alignItems: "center", width: "100%" }}
              >
                <FormControl
                  sx={{ left: "20%", width: "50%", position: positionForVoice }}
                >
                  <Autocomplete
                    options={voices}
                    defaultValue={selectedVoice}
                    name="voice"
                    getOptionLabel={getVoiceLabel}
                    filterOptions={(options, { inputValue }) => {
                      return options.filter(
                        (option) =>
                          option.name
                            .toLowerCase()
                            .includes(inputValue.toLowerCase()) ||
                          option.languageCode
                            .toLowerCase()
                            .includes(inputValue.toLowerCase()) ||
                          option.model
                            .toLowerCase()
                            .includes(inputValue.toLowerCase()) ||
                          option.provider
                            .toLowerCase()
                            .includes(inputValue.toLowerCase()) ||
                          option.accent
                            .toLowerCase()
                            .includes(inputValue.toLowerCase())
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Voice"
                        variant="standard"
                      />
                    )}
                    onChange={(event, newValue) =>
                      handleChange("tts", newValue)
                    }
                    fullWidth
                    margin="normal"
                  />
                </FormControl>
                <Button
                  size="small"
                  variant="contained"
                  sx={{ marginLeft: marginLeftForButton }}
                  onClick={handleDialogOpen}
                >
                  Try Out
                </Button>
              </Box>
            </Grid>

            <Grid item xs={2} alignContent={"right"}>
              <Typography variant="h6">
                {" "}
                Current Price: ~ ${currentPrice}/min
              </Typography>
            </Grid>

            {/* Advanced Settings */}

            <div className="w-full flex flex-col inline">
              <div className="flex w-full justify-between items-center mx-14">
                <div className="flex mr-auto w-content w-[16.666667%]">
                  <Grid item xs={2} alignContent={"left"} className="">
                    <Typography variant="h6">LLM Settings </Typography>
                  </Grid>
                </div>
                <div className=" flex mx-auto w-[66.6666%]">
                  <FormControl
                    sx={{
                      alignItems: "left",
                      width: "60%",
                      flexDirection: "row",
                    }}
                  >
                    <TextField
                      label="Max Tokens"
                      type="number"
                      name="maxTokens"
                      value={formData.modelsConfig.llmConfig.maxTokens || ""}
                      onChange={(e) => handleChange("llm", e)}
                      fullWidth
                      margin="normal"
                      variant="standard"
                    />
                    {renderTooltip(
                      "Max tokens to consider for ouput. More tokens would mean answer will be 1. Verbose and 2. Expensive "
                    )}
                  </FormControl>
                </div>
              </div>

              <Grid item xs={3}></Grid>
              <Grid item xs={6} alignContent={"left"}>
                <Divider>
                  <h2>Advanced Settings</h2>
                </Divider>
              </Grid>
              <Grid item xs={3}></Grid>

              <Grid item xs={2} alignContent={"left"} className="">
                <Typography variant="h6">LLM Settings </Typography>
              </Grid>

              <Grid item xs={8} md={8} spacing={2} sx={{ textAlign: "center" }}>
                <FormControl
                  sx={{
                    alignItems: "left",
                    width: "60%",
                    flexDirection: "row",
                  }}
                >
                  <TextField
                    label="Max Tokens"
                    type="number"
                    name="maxTokens"
                    value={formData.modelsConfig.llmConfig.maxTokens || ""}
                    onChange={(e) => handleChange("llm", e)}
                    fullWidth
                    margin="normal"
                    variant="standard"
                  />
                  {renderTooltip(
                    "Max tokens to consider for ouput. More tokens would mean answer will be 1. Verbose and 2. Expensive "
                  )}
                </FormControl>
                <FormControl sx={{ alignItems: "left", width: "60%" }}>
                  <Typography gutterBottom>
                    Temperature{" "}
                    {renderTooltip(
                      "Temperature allows you to experiment with creative liberties."
                    )}
                  </Typography>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Slider
                      name="temperature"
                      value={formData.modelsConfig.llmConfig.temperature || 0.3}
                      onChange={(e) => handleChange("llm", e)}
                      step={0.01}
                      marks={marks}
                      min={0}
                      max={1}
                      valueLabelDisplay="auto"
                      sx={{ marginRight: "10px" }} // Adds some space between the slider and the value
                    />
                    <Typography sx={{ fontWeight: "bold", marginTop: "-20px" }}>
                      {formData.modelsConfig.llmConfig.temperature || 0.3}
                    </Typography>
                  </Box>
                </FormControl>
              </Grid>

              <Grid item xs={2} alignContent={"left"}></Grid>

              <Grid item xs={4}></Grid>
              <Grid item xs={4}>
                <Divider sx={{ margin: "10px" }}></Divider>
              </Grid>
              <Grid item xs={4}></Grid>

              <Grid item xs={2} alignContent={"left"}>
                <Typography variant="h6">ASR Settings </Typography>
              </Grid>

              <Grid item xs={8} md={8} sx={{ textAlign: "center" }}>
                <FormControl
                  sx={{
                    alignItems: "left",
                    width: "60%",
                    marginTop: "10px",
                    flexDirection: "row",
                  }}
                  gutterBottom
                >
                  <InputLabel>Model Name </InputLabel>
                  <Select
                    name="model"
                    value={formData.modelsConfig.asrConfig.model}
                    onChange={(e) => handleChange("asr", e)}
                    variant="standard"
                    fullWidth
                  >
                    {asrModels.map((model, index) => (
                      <MenuItem key={index} value={model}>
                        {model}
                      </MenuItem>
                    ))}
                  </Select>
                  {renderTooltip(
                    "These models will convert speech to text. Whisper is an open source model and hence cheapest but others might be better for your usecase."
                  )}
                </FormControl>

                <FormControl
                  sx={{
                    alignItems: "left",
                    width: "60%",
                    flexDirection: "row",
                  }}
                >
                  <TextField
                    label="Keywords (Comma seperated)"
                    type="text"
                    name="keywords"
                    value={formData.modelsConfig.asrConfig.keywords || ""}
                    onChange={(e) => handleChange("asr", e)}
                    fullWidth
                    margin="normal"
                    variant="standard"
                  />
                  {renderTooltip(
                    "Enter certain keywords/proper nouns you'd want to boost while understanding speech. You can also add a boosting factor between 0-10 ex: marmik:5"
                  )}
                </FormControl>

                {/* <FormControl sx={{ alignItems: "left", width: "60%", marginTop: '10px', flexDirection: 'row' }} gutterBottom>
                        <InputLabel>Sampling Rate</InputLabel>
                        <Select
                            disabled={formData.engagementConfig.channel == "Telephone"}
                            name="samplingRate"
                            value={formData.modelsConfig.asrConfig.samplingRate || ''}
                            onChange={e => handleChange("asr", e)}
                            variant='standard'
                            fullWidth
                        >
                            {samplingRates.map((rate, index) => (
                                <MenuItem key={index} value={rate}>{rate}</MenuItem>
                            ))}
                        </Select>
                        {renderTooltip("If your chosen engagement setting is websocket, kindly let us know the sample rate at which we will receive the audio")}
                    </FormControl> */}

                <FormControl
                  sx={{
                    alignItems: "left",
                    width: "60%",
                    marginTop: "10px",
                    flexDirection: "row",
                  }}
                >
                  <InputLabel>Streaming</InputLabel>
                  <Select
                    name="streaming"
                    value={formData.modelsConfig.asrConfig.streaming}
                    onChange={(e) => handleChange("asr", e)}
                    variant="standard"
                    fullWidth
                  >
                    <MenuItem value={true}>True</MenuItem>
                    <MenuItem value={false}>False</MenuItem>
                  </Select>
                  {renderTooltip(
                    "This will enable truly human like conversation"
                  )}
                </FormControl>

                <br />

                <FormControl
                  sx={{ alignItems: "left", width: "60%", marginTop: "10px" }}
                >
                  <Typography gutterBottom>
                    Endpointing (Silence length in ms)
                    {renderTooltip(
                      "This setting allows the agent to understand if the human has stopped speaking. For example, with 400ms, the agent will start speaking only after there's a silence of 400ms from the human's end."
                    )}
                  </Typography>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Slider
                      name="endpointing"
                      value={formData.modelsConfig.asrConfig.endpointing || 400}
                      onChange={(e) => handleChange("asr", e)}
                      step={50}
                      marks={marks}
                      min={100}
                      max={2500}
                      valueLabelDisplay="auto"
                      sx={{ marginRight: "10px" }} // Add some space between the slider and the value
                    />
                    <Typography sx={{ fontWeight: "bold" }}>
                      {formData.modelsConfig.asrConfig.endpointing || 400} ms
                    </Typography>
                  </Box>
                </FormControl>
              </Grid>

              <Grid item xs={2}></Grid>

              <Grid item xs={4}></Grid>
              <Grid item xs={4}>
                <Divider sx={{ margin: "10px" }}></Divider>
              </Grid>
              <Grid item xs={4}></Grid>

              {/* TTS Settings */}
              <Grid item xs={2} alignContent={"left"}>
                <Typography variant="h6">TTS Settings</Typography>
              </Grid>

              <Grid item xs={12} md={8} sx={{ textAlign: "center" }}>
                <FormControl
                  sx={{
                    alignItems: "left",
                    width: "60%",
                    marginTop: "-1%",
                    flexDirection: "row",
                  }}
                >
                  <TextField
                    label="Buffer Size"
                    type="number"
                    name="bufferSize"
                    value={formData.modelsConfig.ttsConfig.bufferSize || ""}
                    onChange={(e) => handleChange("tts", e)}
                    fullWidth
                    margin="normal"
                    variant="standard"
                  />
                  {renderTooltip(
                    "LLM will generally output token and we will buffer those tokens before generating audio. Play with these settings to bring the best human like qualities for your assistant."
                  )}
                </FormControl>
              </Grid>

              <Dialog
                open={isDialogOpen}
                onClose={handleDialogClose}
                fullWidth
                maxWidth="md"
              >
                <VoiceLab
                  setVoices={setVoices}
                  accessToken={accessToken}
                  voices={voices}
                  defaultValue={selectedVoice}
                />
              </Dialog>

              <Grid item xs={2} alignContent={"left"}></Grid>

              <Grid item xs={4}></Grid>
              <Grid item xs={4}>
                <Divider sx={{ margin: "10px" }}></Divider>
              </Grid>
              <Grid item xs={4}></Grid>

              <Grid item xs={2} alignContent={"left"}>
                <Typography variant="h6">
                  Conversation Nuances Settings
                </Typography>
              </Grid>
              <Grid item xs={8} md={8} sx={{ textAlign: "center" }}>
                <FormControl
                  sx={{ alignItems: "left", width: "60%", marginTop: "10px" }}
                >
                  <Typography gutterBottom>
                    Linear delay for long pauses (in ms)
                    {renderTooltip(
                      "Everytime we have an interim transcript, we add a delay to make sure we account for possible long pauses."
                    )}
                  </Typography>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Slider
                      name="incrementalDelay"
                      value={
                        formData.modelsConfig.nuancesConfig.incrementalDelay ||
                        100
                      }
                      onChange={(e) => handleChange("nuances", e)}
                      step={50}
                      min={100}
                      max={2500}
                      marks
                      valueLabelDisplay="auto"
                      sx={{ marginRight: "10px" }} // Add some space between the slider and the value
                    />
                    <Typography sx={{ fontWeight: "bold" }}>
                      {formData.modelsConfig.nuancesConfig.incrementalDelay ||
                        100}{" "}
                      ms
                    </Typography>
                  </Box>
                </FormControl>
                <br />

                <FormControl
                  sx={{ alignItems: "left", width: "60%", marginTop: "10px" }}
                >
                  <Typography gutterBottom>
                    Number of words to wait for before interrupting
                    {renderTooltip(
                      "We'll wait for x amount of words before interrupting"
                    )}
                  </Typography>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Slider
                      name="interruptionAfter"
                      value={
                        formData.modelsConfig.nuancesConfig.interruptionAfter ||
                        1
                      }
                      onChange={(e) => handleChange("nuances", e)}
                      step={1}
                      min={1}
                      max={10}
                      marks
                      valueLabelDisplay="auto"
                      sx={{ marginRight: "10px" }} // Add some space between the slider and the value
                    />
                    <Typography sx={{ fontWeight: "bold" }}>
                      {formData.modelsConfig.nuancesConfig.interruptionAfter ||
                        1}{" "}
                      word(s)
                    </Typography>
                  </Box>
                </FormControl>

                <br />

                {/* <FormControl sx={{ alignItems: "left", width: "60%", marginTop: '10px' }}>
                        <Typography gutterBottom>
                            Backoff period after interruption to determine if it was an accidental interruption (in miliseconds)
                            {renderTooltip("We'll stop speaking for x miliseconds post interruption to know if it were an accidental or not")}
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Slider
                                name="interruptionBackOffPeriod"
                                value={formData.modelsConfig.nuancesConfig.interruptionBackOffPeriod || 100}
                                onChange={e => handleChange("nuances", e)}
                                step={50}
                                min={0}
                                max={3000}
                                sx={{ marginRight: '10px' }} // Add some space between the slider and the value
                            />
                            <Typography sx={{ fontWeight: 'bold' }}>
                                {formData.modelsConfig.nuancesConfig.interruptionBackOffPeriod || 100} ms
                            </Typography>
                        </Box>
                    </FormControl> */}

                <FormControl
                  sx={{ alignItems: "left", width: "60%", marginTop: "10px" }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <FormLabel
                      component="legend"
                      sx={{ fontWeight: "bold", marginRight: 2 }}
                    >
                      Call Hangup Logic
                    </FormLabel>
                    <RadioGroup
                      row
                      name="hangUpAfterLLMCall"
                      value={callHangupViaPrompt}
                      onChange={handleRadioChange}
                    >
                      <FormControlLabel
                        value="true"
                        control={<Radio />}
                        label="Via Prompt"
                      />
                      <FormControlLabel
                        value="false"
                        control={<Radio />}
                        label="Static time based hangup"
                      />
                    </RadioGroup>
                    {renderTooltip(
                      "This will determine if we will hang the agent up via prompt or use a static duration based logic like wait for X seconds of silence before hanging the call up"
                    )}
                  </Box>
                </FormControl>
                {callHangupViaPrompt == "true" ? (
                  <>
                    <FormControl fullWidth margin="normal">
                      <TextField
                        label="Hangup Prompt"
                        multiline
                        rows={4}
                        defaultValue={
                          formData?.modelsConfig.nuancesConfig
                            .callCancellationPrompt
                        }
                        margin="normal"
                        placeholder="Prompt for llm to determine when to hang up the call"
                        name="callCancellationPrompt"
                        onChange={(e) => handleChange("nuances", e)}
                      />
                      {renderTooltip(
                        "Since after every exchange we send a call to llm to determine if we should hang up a call or not, this will be expensive"
                      )}
                    </FormControl>
                  </>
                ) : (
                  <>
                    <FormControl
                      sx={{
                        alignItems: "left",
                        width: "60%",
                        marginTop: "10px",
                      }}
                    >
                      <Typography gutterBottom>
                        Seconds of silence to wait for before hanging up (in
                        seconds)
                        {renderTooltip(
                          "We'll these many seconds before cutting the phone call"
                        )}
                      </Typography>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Slider
                          name="hangupAfterSilence"
                          value={
                            formData.modelsConfig.nuancesConfig
                              .hangupAfterSilence || 10
                          }
                          onChange={(e) => handleChange("nuances", e)}
                          step={1}
                          min={0}
                          marks
                          max={30}
                          valueLabelDisplay="auto"
                          sx={{ marginRight: "10px" }} // Add some space between the slider and the value
                        />
                        <Typography sx={{ fontWeight: "bold" }}>
                          {formData.modelsConfig.nuancesConfig
                            .hangupAfterSilence || 10}{" "}
                          seconds
                        </Typography>
                      </Box>
                    </FormControl>

                    <br />
                  </>
                )}
              </Grid>
            </div>
          </Grid>
        </>
      )}
    </form>
  );
}

export default ModelSettings;

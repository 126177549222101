import React from "react";
import {
  Box,
  Typography,
  Divider,
  Grid,
  Paper,
  AudioPlayer,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import Subheader from "../../../components/icons/HeaderBreadCrumb/subheader";
import DetailWidget from "../../../components/DetailWidget";
function ExecutionMetadata({ executionDetails }) {
  const lines = executionDetails.transcript
    .trim()
    .split("\n")
    .filter((line) => line.trim() !== "");

  const data = lines.map((line) => {
    const [role, ...messageParts] = line.split(":");
    const message = messageParts.join(":").trim();
    return { role: role.trim(), message };
  });
  const MetaData = {
    "Run ID": executionDetails.id,
    "Run Duration": `${executionDetails.conversation_time} seconds`,
    "Run Date": new Date(executionDetails.createdAt).toLocaleDateString(),
    "Total Credits Used": `${executionDetails.total_cost.toFixed(5)}`,
    "Telephony Used": executionDetails.telephony_data?.hosted_telephony
      ? "bolna hosted"
      : "self hosted",
  };
  return (
    <div className="flex flex-col md:flex-row w-full px-2 gap-x-6 h-full ">
      <div className="pt-10 w-full md:w-1/2">
        <p className="font-semibold text-xl">Metadata</p>
        <div className=" flex justify-between pt-2">
          <DetailWidget data={MetaData} />
        </div>
        <div className="">
          <p className="pt-10 font-semibold text-xl "> Usage Breakdown</p>

          <div className=" flex justify-between pt-2">
            <DetailWidget data={executionDetails.usage_breakdown} />
          </div>
        </div>
        <div className="">
          <p className="pt-10 font-semibold text-xl">
            Latency Details (In seconds)
          </p>
          <div className=" flex justify-between pt-2">
            <DetailWidget data={executionDetails.average_latency} />
          </div>
        </div>
      </div>
      <div className=" pt-10 w-full md:w-1/2">
        <div>
          <p className="font-semibold text-xl ">Transcript</p>
          <div className="pt-2">
            <div className="flex flex-col bg-white text-black gap-y-3 rounded-xl border-solid border-[#1D2939] border-[1px] justify-between px-4 py-2">
              {data.map((entry, index) => (
                <div key={index} className="flex flex-col">
                  <div className="flex gap-x-2">
                    <p className="text-black min-w-[80px]">
                      {entry.role === "user" ? "User:" : "Assistant:"}
                    </p>
                    <p className="text-black flex-grow">{entry.message}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExecutionMetadata;

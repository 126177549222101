import React, { useEffect, useState } from "react";
import axios from "axios";
import { Box, Typography, CircularProgress } from "@mui/material";

import JsonTable from "../components/Table"; // Adjust the import path as necessary
import Backdrop from "@mui/material/Backdrop";
import createApiInstance from "../utils/api";
import { Mixpanel } from "../utils/mixpanel";
import { useNavigate } from "react-router-dom";
import { Add, ExpandMore, Search } from "@mui/icons-material";
import TableContents from "../components/icons/TableContents";
import moment from "moment";
import AgentPrompt from "../components/AgentPrompt";
function MyAgents({
  accessToken,
  user,
  agents,
  setAgents,
  isLoading,
  setIsLoading,
  error,
  setError,
  agentCreationCounter,
  setAgentCreationCounter,
}) {
  const api = createApiInstance(accessToken);

  const [showCreateAgent, setshowCreateAgent] = useState(false);
  const headers = [
    "Agent Name",
    "Task",
    "Status",
    "Last Updated",
    "Created On",
    " ",
  ];
  const imgSources = [];
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    if (event.target.value === "") {
      setSearchResults(currentData);
    } else {
      const filterAgents = setSearchResults(() => {
        return currentData.filter((agent) =>
          agent.agent_name.toLowerCase().includes(searchTerm.toLowerCase())
        );
      }, [currentData, searchTerm]);
    }
  };

  const extractFields = (data) => {
    return data.map((agent, index) => {
      console.log();
      imgSources.push(
        "/" +
          agent.tasks[0].tools_config.synthesizer.provider_config.voice_id +
          ".jpg"
      );
      return {
        agent_name: agent.agent_name,
        agent_type: agent.agent_type,
        agent_status:
          agent.agent_status === "processed" ? "Active" : "Inactive",
        updated_at: moment(agent.updated_at).format("dddd, MMMM D, YYYY"),
        created_at: moment(agent.created_at).format("dddd, MMMM D, YYYY"),
      };
    });
  };

  const navigate = useNavigate();
  const currentData = extractFields(agents);
  const [searchResults, setSearchResults] = useState(currentData);

  useEffect(() => {
    const fetchAgents = async () => {
      setError(null);
      Mixpanel.track("login");
      setIsLoading(true);
      try {
        setSearchResults(extractFields(agents));
      } catch (error) {
        console.error("Error fetching agents: Making loading false", error);
        setIsLoading(false);
      } finally {
        setIsLoading(false);
      }
    };

    if (accessToken) {
      fetchAgents();
    }
  }, [accessToken, agents]);

  if (error) {
    return <Typography>Error loading agents.</Typography>;
  }

  const handleNavigation = (url) => {
    if (url.startsWith("mailto")) {
      window.location.href = url; // Handle mailto links
    } else if (!url.startsWith("https")) {
      navigate(url); // Handle internal navigation
    } else {
      window.open(url, "_blank"); // Open external URL in new tab
    }
  };

  const recentAgentsM = (
    <div className="sm:hidden mt-8">
      {agents && agents.length > 0 && (
        <div className="">
          <p className="text-xl pl-3 pb-2 text-left text-black">
            Recent Agents
          </p>
          <hr className="mb-2 border-bottom border-1 border-black" />
          {agents.slice(0, 2).map((agent, index) => (
            <div
              key={index}
              className={`flex hover:cursor-pointer border-2 border-white border-solid px-2 mb-1 hover:border-2 hover:border-black hover:border-solid items-center w-4/4 text-xs md:text-md gap-x-2 py-1.5 rounded-lg text-black`}
              onClick={() => {
                navigate("/dashboard/agent-details/", {
                  state: {
                    user: user,
                    agent: agent,
                  },
                });
              }}
            >
              <img
                // src={imgSources[index] ?? "/femaleAvatar.png"}
                src={"/femaleAvatar.png"}
                alt="Avatar"
                className="h-10 w-10 rounded-full"
                onError={(e) => (e.target.src = "/femaleAvatar.png")}
              />
              <span className="break-words text-xs text-black md:text-base">
                {agent.agent_name}
              </span>
            </div>
          ))}
        </div>
      )}
    </div>
  );

  return (
    <div className="h-full w-full bg-[06090F] text-white">
      <div>
        <div className="flex justify-between items-center">
          <p className="text-3xl font-semibold text-black">My Agents</p>

          <div
            className="bg-black text-white cursor-pointer flex items-center  rounded-md gap-x-2 py-0 px-4 py-2"
            onClick={() => {
              setshowCreateAgent(!showCreateAgent);
            }}
          >
            <Add />
            <p className="">New Agent</p>
          </div>
        </div>
        {showCreateAgent && (
          <AgentPrompt
            accessToken={accessToken}
            user={user}
            setshowCreateAgent={setshowCreateAgent}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            setAgentCreationCounter={setAgentCreationCounter}
          />
        )}
      </div>
      {isLoading && (
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <Box sx={{ display: "flex" }}>
            <CircularProgress color="warning" />
          </Box>
        </div>
      )}
      {!isLoading && agents.length > 0 && (
        <div>
          <div className="md:flex items-center text-gray-400 w-full gap-x-5 pt-7 hidden ">
            <div className="bg-white text-black rounded-md w-1/4 flex px-4 gap-x-2 items-center relative py-2 border-2 border-black border-solid">
              <Search />

              <input
                value={searchTerm}
                onChange={handleSearchChange}
                placeholder="Search by Name"
                className="bg-white rounded-lg w-full flex items-center border-none text-black focus:outline-none placeholder-gray-400"
              />
            </div>
            <div className="bg-white border-2 border-black border-solid text-black rounded-md px-4  py-2 flex items-center gap-x-2">
              <p>Agent Details</p>
              <ExpandMore />
            </div>
            <div className="bg-white border-2 border-black border-solid  text-black rounded-md px-4  py-2 flex items-center gap-x-2">
              <p>Task</p>
              <ExpandMore />
            </div>
            <div className="bg-white border-2 border-black border-solid text-black rounded-md px-4  py-2 flex items-center gap-x-2">
              <p>Status</p>
              <ExpandMore />
            </div>
          </div>
          <div className="w-full overflow-hidden  bg-[06090F] pt-7  md:flex">
            <TableContents
              data={agents}
              currentData={searchResults}
              headers={headers}
              user={user}
              imgSources={imgSources}
              copyTrue={true}
              clickable={true}
            />
          </div>
          {recentAgentsM}
        </div>
      )}
      {!isLoading && agents.length <= 0 && (
        <div className="flex justify-center items-center h-full">
          <div className="flex flex-col justify-center items-center gap-y-4 mt-10">
            <h1 className="text-4xl 2xl:text-5xl text-black font-bold bg-clip-text text-transparent bg-gradient-to-r from-[#0032F6] to-[#1DCAF8]">
              Welcome :)
            </h1>
            <div class="xl:text-2xl text-lg md:text-xl text-center mb-4 text-slate-900 font-semibold ">
              <p>To get started, create your first AI agent by</p>
              <p>clicking the button below. Once you create your</p>
              <p> agent, you can place it on live calls in minutes.</p>
            </div>
            <div
              className="bg-gradient-to-r from-[#0032F6] to-[#1DCAF8]  text-white cursor-pointer flex items-center  rounded-md gap-x-2 py-0 px-4 py-2"
              onClick={() => {
                setshowCreateAgent(!showCreateAgent);
              }}
            >
              <p className="">Create an Agent</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default MyAgents;
{
  /* <Box>
<Typography textAlign={"left"} variant="h3" gutterBottom>
  {" "}
  My Agents{" "}
</Typography>
{isLoading ? (
  <Backdrop
    sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
    open={isLoading}
  >
    <CircularProgress color="inherit" />
  </Backdrop>
) : (
  <Box>
    <div className="cursor-pointer">
      <JsonTable
        className="cursor-pointer "
        sx={{ width: "70%" }}
        jsonData={agents}
        user={user}
        columnsToShow={[
          "agent_name",
          "agent_type",
          "agent_status",
          "updated_at",
          "created_at",
        ]}
        onClickPage={"agent-details"}
        clickable={true}
        accessToken={accessToken}
        actionsToShow={{
          Copy: {
            id: "id",
            name: "agent_name",
          },
        }}
        headersDisplayedAs={[
          "Agent Name",
          "Agent Task",
          "Agent Status",
          "Last Updated",
          "Created On",
        ]}
        dateColumns={["updated_at", "created_at"]}
      />
    </div>
  </Box>
)}
</Box> */
}

import React from 'react';
import { Box } from '@mui/material';

function CreateAgents() {

    return (
        <Box sx={{ display: 'flex' }}>
            <h1> CreateAgents </h1>
        </Box>
    );
}

export default CreateAgents;

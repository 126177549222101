import React, { useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import TollIcon from "@mui/icons-material/Toll";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  IconButton,
  Typography,
  Box,
  Toolbar,
  AppBar,
  ListItemSecondaryAction,
  Badge,
  Avatar,
} from "@mui/material";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Popover,
  MenuItem,
  Stack,
  ListSubheader,
  ListItemButton,
} from "@mui/material";
import { Close, Menu as MenuIcon, Support, Toll } from "@mui/icons-material";
import { LinearProgress } from "@mui/material";
import { NavLink, Outlet, useLocation } from "react-router-dom";
import GroupIcon from "@mui/icons-material/Group"; // Icon for "My Agents"
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline"; // Icon for "Create Agents"
import InsightsIcon from "@mui/icons-material/Insights"; // Icon for "Models"
import StorageIcon from "@mui/icons-material/Storage"; // Icon for "Datasets"
import SettingsInputComponentIcon from "@mui/icons-material/SettingsInputComponent"; // Icon for "Integrations"
import DataObjectIcon from "@mui/icons-material/DataObject";
import PaymentIcon from "@mui/icons-material/Payment";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import ArticleIcon from "@mui/icons-material/Article";
import ChatIcon from "@mui/icons-material/Chat";
import SupportIcon from "./icons/Support";
import DocumentationIcon from "./icons/Documentation";
import TextToSpeechIcon from "./icons/text-to-speech";
import CreditDialog from "./CreditDialog";
import { Mixpanel } from "../utils/mixpanel";
import Logo from "./icons/Logo";
import LogoutIcon from "./icons/Logout";
import MailIcon from "./icons/mail";
import FAQIcon from "./icons/faq";
const drawerWidth = 260;
const MENU_OPTIONS = [
  {
    label: "Add More Credits",
    icon: "eva:home-fill",
    action: "ADD_CREDITS",
  },
];

function Dashboard({ supabase, agents, accessToken, userInfo = null, user }) {
  //console.log("accessToken1", accessToken);

  const [mobileOpen, setMobileOpen] = useState(false);
  const [accountOpen, setAccountOpen] = useState(null);
  const [openCreditDialog, setOpenCreditDialog] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const [showBanner, setShowBanner] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  const imgSources = [];
  let credits = userInfo?.wallet || 0;
  credits = parseFloat(credits).toFixed(2);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const logoutUser = () => {
    Mixpanel.identify(userInfo.user_id);
    Mixpanel.track("logout");
    supabase.auth.signOut().then(() => {
      alert("Logged out");
      navigate("/");
    });
  };

  const handleAccountOpen = (event) => {
    Mixpanel.identify(userInfo.user_id);
    Mixpanel.track("open_profile");
    setAccountOpen(event.currentTarget);
  };

  const handleAccountClose = () => {
    setAccountOpen(null);
  };

  const handleMenuItemClick = (action) => {
    if (action === "ADD_CREDITS") {
      Mixpanel.identify(userInfo.user_id);
      Mixpanel.track("click_profile_add_credits");
      setOpenCreditDialog(true);
    }
  };

  const handleCreditCloseDialog = () => {
    setOpenCreditDialog(false);
    setAccountOpen(null);
  };
  const handleNavigation = (url) => {
    if (url.startsWith("mailto")) {
      window.location.href = url; // Handle mailto links
    } else if (!url.startsWith("https")) {
      navigate(url); // Handle internal navigation
    } else {
      window.open(url, "_blank"); // Open external URL in new tab
    }
  };

  agents.map((agent, index) => {
    imgSources.push(
      "/" +
        agent.tasks[0].tools_config.synthesizer.provider_config.voice_id +
        ".jpg"
    );
  });

  const handleNavLinkClick = (e) => {
    //console.log(e.target.innerText);
    // Track event using Mixpanel
    Mixpanel.track("nav_item_clicked", {
      item: e.target.innerText,
    });
  };

  const DrawerItems = [
    {
      agentLabel: "My Agents",
      IconComponent: SupportIcon,
      url: "/dashboard/my-agents",
    },
    {
      agentLabel: "Voice Lab",
      IconComponent: TextToSpeechIcon,
      url: "/dashboard/models",
    },
    {
      agentLabel: "Documentation",
      IconComponent: DocumentationIcon,
      url: "https://outcallai.mintlify.app/introduction",
    },
    {
      agentLabel: "FAQ",
      IconComponent: FAQIcon,
      url: "https://outcallai.mintlify.app/introduction",
    },
    {
      agentLabel: "Contact us",
      IconComponent: MailIcon,
      url: "mailto:contact@outcall.app",
    },
  ];

  const recentAgents = (
    <div>
      {agents && agents.length > 0 && (
        <div className="">
          <p className="text-xl pl-3 pb-2 text-left">Recent Agents</p>
          <hr className="mb-2 border-bottom border-1 border-black" />
          {agents.slice(0, 2).map((agent, index) => (
            <div
              key={index}
              className={`flex hover:cursor-pointer border-2 border-white border-solid px-2 mb-1 hover:border-2 hover:border-black hover:border-solid items-center w-4/4 text-xs md:text-md gap-x-2 py-1.5 rounded-lg text-black`}
              onClick={() => {
                navigate("/dashboard/agent-details/", {
                  state: {
                    user: user,
                    agent: agent,
                  },
                });
              }}
            >
              <img
                src={imgSources[index] ?? "/femaleAvatar.png"}
                /* src={"/femaleAvatar.png"} */
                alt="Avatar"
                className="h-10 w-10 rounded-full"
                onError={(e) => (e.target.src = "/femaleAvatar.png")}
              />
              <span className="break-words text-xs text-black md:text-base">
                {agent.agent_name}
              </span>
            </div>
          ))}
        </div>
      )}
    </div>
  );

  const drawer = (
    <div className=" flex flex-col bg-white justify-between px-2 h-full border-0 border-r-[1px] border-solid border-[#000000] border-opacity-100">
      <div>
        <div className=" flex pl-3 justify-left gap-x-2  items-center py-4  ">
          <p className="text-black text-2xl font-semibold">
            Outcall <span className="text-[#7F56D9] text-2xl ">AI</span>
          </p>
        </div>

        {DrawerItems.map((item, index) => (
          <div
            className={`flex justify-left cursor-pointer  gap-x-3 py-2 items-center  rounded-lg    ${
              location.pathname === item.url
                ? "border-2 border-black border-solid"
                : ""
            } `}
            onClick={() => {
              handleNavigation(item.url);
            }}
            key={index}
          >
            <div className="pl-2">
              <item.IconComponent className="text-black" />
            </div>

            <p className="text-black text-lg">{item.agentLabel}</p>
          </div>
        ))}
      </div>

      {recentAgents}

      <div className="  text-black   ">
        <div className="h-40 pt-2 w-full px-3 border-black border-2 border-solid rounded-lg justify-center items-left flex gap-y-3 pb-2 mb-2 flex-col">
          <div>
            <p className="text-black text-lg text-semibold">Credits</p>
          </div>
          <div>
            <p className="text-sm text-green-500">
              {Math.floor(credits) || 0}{" "}
              <span className="text-black">Available Credits</span>
            </p>
          </div>
          <div className="relative flex items-center ">
            <div className="flex-grow mr-2">
              <LinearProgress
                className="w-full"
                variant="determinate"
                value={
                  (credits / 1000) * 100 > 100 ? 100 : (credits / 1000) * 100
                }
                sx={{
                  height: 10,
                  borderRadius: 5,
                  backgroundColor: "#475467",
                  "& .MuiLinearProgress-bar": {
                    backgroundColor: "green",
                  },
                }}
              />
            </div>

            <div className="">
              <p className="text-xs text-right text-black  ">
                {(credits / 1000) * 100 > 100
                  ? "100+"
                  : Math.floor((credits / 1000) * 100).toFixed(0) + "%"}
              </p>
            </div>
          </div>
          <div>
            <p className=" text-lg text-semibold cursor-pointer text-black">
              Add Credits
            </p>
          </div>
        </div>
        <div className="flex pb-5 justify-evenly gap-x-2  items-center border-t-2 border-x-0 border-b-0 pt-4 border-solid border-[#475467] ">
          {/* <div className="h-8 w-8 rounded-full border-solid border-[1px] border-black flex justify-center items-center">
            <p className="">
              {userInfo?.user_info?.name
                ? userInfo?.user_info?.name.charAt(0).toUpperCase()
                : userInfo?.user_info?.email.charAt(0).toUpperCase()}
            </p>
          </div> */}
          <img
            /* src={imgSources[index] ?? "/femaleAvatar.png"} */
            src={"/ambiAvatar.png"}
            alt="Avatar"
            className="h-10 w-10 rounded-full"
            onError={(e) => (e.target.src = "/femaleAvatar.png")}
          />
          <div className="justify-left text-left gap-y-1 overflow-hidden break-words ">
            <div>{userInfo?.user_info?.name}</div>
            <div className=""> {userInfo?.user_info?.email}</div>
          </div>
          <div
            className="flex items-center cursor-pointer"
            onClick={logoutUser}
          >
            <LogoutIcon />
          </div>
        </div>
      </div>
    </div>
  );

  const pageTitle = useMemo(() => {
    const path = location.pathname.split("/").pop();
    switch (path) {
      case "my-agents":
        return "My Agents";
      case "create-agents":
        return "Create Agents";
      case "models":
        return "Models";
      case "datasets":
        return "Datasets";
      case "integrations":
        return "Integrations";
      case "developer":
        return "Developer";
      default:
        return "Dashboard";
    }
  }, [location]);

  return (
    <Box component="main" sx={{ display: "flex" }} injectFirst>
      <AppBar position="fixed" sx={{}}>
        <div
          className={
            showBanner
              ? "sm:hidden flex  bg-gradient-to-r from-[#dd34cc] via-[#8d39cd] to-[#1f5dd1] flex items-center"
              : " p-1 "
          }
        >
          {/* <IconButton
            className=""
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ display: { sm: "none" } }}
          >
            <MenuIcon className="ml-2 text-3xl" />
          </IconButton> */}
          {showBanner ? (
            <div className=" w-full flex py-1 px-2 flex justify-between items-center   text-center   text-white">
              <div className=" flex grow flex-col">
                <p>For full features and the best experience,</p>
                <p> view this site on a desktop browser.</p>
              </div>
              <div
                className=""
                onClick={(e) => {
                  setShowBanner(false);
                }}
              >
                {/* <Close /> */}
              </div>
            </div>
          ) : null}
        </div>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
      >
        <Drawer
          variant="permanent"
          anchor="bottom"
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
            },
          }}
        >
          <div className="flex flex-row bg-white justify-between py-2 px-2 w-full border-0 border-t-[1px] border-solid border-[#000000] border-opacity-100">
            {DrawerItems.map((item, index) => (
              <div
                className={`flex justify-center cursor-pointer  gap-x-3 py-2 items-center  rounded-lg    ${
                  location.pathname === item.url
                    ? "border-2 border-black border-solid"
                    : ""
                } ${index !== 3 && index !== 4 ? "" : "hidden md:flex"}
                 `}
                onClick={() => {
                  handleNavigation(item.url);
                }}
                key={index}
              >
                <div className="px-2">
                  <item.IconComponent className="text-black" />
                </div>
              </div>
            ))}
            <div
              className="flex justify-center cursor-pointer px-2 gap-x-3 py-2 items-center rounded-lg"
              onClick={handleDrawerToggle}
            >
              <img
                /* src={imgSources[index] ?? "/femaleAvatar.png"} */
                src={"/ambiAvatar.png"}
                alt="Avatar"
                className="h-10 w-10 rounded-full"
                onError={(e) => (e.target.src = "/femaleAvatar.png")}
              />
            </div>
          </div>
        </Drawer>
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          anchor="bottom"
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: "100%",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              backgroundColor: "white",
              justifyContent: "space-between",
              paddingLeft: 2,
              paddingRight: 2,
              borderRight: "1px solid rgba(238, 239, 252, 0.2)",
            }}
          >
            <Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 1,
                  paddingY: 2,
                  paddingLeft: 1,
                }}
              >
                <Typography
                  variant="h5"
                  sx={{
                    color: "black",
                    fontWeight: "600",
                    fontFamily: "Avenir Next",
                  }}
                >
                  Outcall <span style={{ color: "#7F56D9" }}>AI</span>
                </Typography>
              </Box>

              {/* {DrawerItems.map((item, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 3,
                    paddingY: 1.5,
                    paddingLeft: 2,
                    borderRadius: 2,
                    cursor: "pointer",

                    border:
                      location.pathname === item.url ? "black 1px solid" : "",
                    "&:hover": {},
                  }}
                  onClick={() => handleNavigation(item.url)}
                >
                  <item.IconComponent />
                  <Typography variant="body1" sx={{ color: "black" }}>
                    {item.agentLabel}
                  </Typography>
                </Box>
              ))} */}
            </Box>

            <Box sx={{ color: "black", paddingBottom: 5 }}>
              <Divider sx={{ borderColor: "#475467" }} />
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 2,
                  paddingTop: 4,
                  paddingLeft: 1,
                }}
              >
                <img
                  /* src={imgSources[index] ?? "/femaleAvatar.png"} */
                  src={"/ambiAvatar.png"}
                  alt="Avatar"
                  width={30}
                  className="h-10 w-10 rounded-full"
                  onError={(e) => (e.target.src = "/femaleAvatar.png")}
                />
                <Box
                  sx={{
                    textAlign: "left",
                    wordWrap: "break-word",
                    overflowWrap: "break-word",
                    whiteSpace: "normal",
                    overflow: "hidden",
                  }}
                >
                  <Typography variant="body1">
                    {userInfo?.user_info?.name}
                  </Typography>
                  <Typography
                    variant="body3"
                    sx={{
                      color: "black",
                      fontWeight: "600",
                      fontFamily: "Avenir Next",
                    }}
                  >
                    {userInfo?.user_info?.email}
                  </Typography>
                </Box>
                <IconButton onClick={logoutUser}>
                  <LogoutIcon />
                </IconButton>
              </Box>
            </Box>
          </Box>
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            backgroundColor: "gray",
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />
        <Outlet />
      </Box>
    </Box>
  );
}

export default Dashboard;

import mixpanel from 'mixpanel-browser';

const MIXPANEL_TOKEN = process.env.REACT_APP_MIXPANEL_TOKEN;
const MIXPANEL_CONFIG = {
  track_pageview: true
};

mixpanel.init(MIXPANEL_TOKEN);

let env_check = true;//process.env.NODE_ENV === 'production';

let actions = {
  identify: (id) => {
    try {
      if (env_check) mixpanel.identify(id);
    } catch (error) {
      console.error('Error occurred while identifying user in Mixpanel:', error);
    }
  },
  alias: (id) => {
    try {
      if (env_check) mixpanel.alias(id);
    } catch (error) {
      console.error('Error occurred while aliasing user in Mixpanel:', error);
    }
  },
  track: (name, props) => {
    try {
      if (env_check) mixpanel.track(name, props);
    } catch (error) {
      console.error('Error occurred while tracking event in Mixpanel:', error);
    }
  },
  people: {
    set: (props) => {
      try {
        if (env_check) mixpanel.people.set(props);
      } catch (error) {
        console.error('Error occurred while setting people properties in Mixpanel:', error);
      }
    },
  },
};

export let Mixpanel = actions;
import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Box,
} from "@mui/material";
import CustomTabs from "../../components/CustomTabs";
import PromptEditor from "../../components/PromptEditor";

function Prompts({ formData, onFormDataChange }) {
  console.log(formData.rulesConfig.prompts.assistantDescription);
  const handleEditorChange = (key, content) => {
    //console.log(`Current KEy ${key}`)
    onFormDataChange({
      ...formData,
      rulesConfig: {
        prompts: { ...formData.rulesConfig.prompts, [key]: content },
      },
    });
    //console.log(`FORM DATA ${key} ${JSON.stringify(formData.rulesConfig.prompts)}`)
    //console.log(`Text ${formData.rulesConfig.prompts[key]}`)
  };

  var lines = [
    { content: "AI: Hey is this {prospect_name}", editable: true },
    { content: "User: Yes", editable: false },
    {
      content: "AI: Hows it been going? Do you have a second talk?",
      editable: true,
    },
    { content: "User: Yes for sure thats cool.", editable: false },
    { content: "*Start editing from here*", editable: true },
  ];

  var exampleConversation = lines.map((line) => ({
    type: "paragraph",
    children: [{ text: line.content, editable: line.editable }],
  }));

  //console.log(`Example conversation ${JSON.stringify(exampleConversation)}`)

  if (formData.rulesConfig.prompts.exampleConversation != null) {
    exampleConversation = formData.rulesConfig.prompts.exampleConversation;
  }

  var agentDesctiptionText =
    "Give a short description of the agent (You are Zack, a sales agent for the company Sneakers Inc) \n Give a short description of the company (Sneakers Inc sells 2nd hand sneakers of leading brands like Nike, Adidas and New Balance) \n Give a short description of the primary intent (Your primary goal is to convince the user to buy sneakers from your company) \n Give a short brief of the major steps your agent has to follow (You have 3 primary tasks - To help find the right sneakers for the agent, to understand the shipping details, and to get them to confirm that they are interested in buying, following which you will send a whatsapp message with a purchase link) \n Give a short description of when the task is done (Your task is done either when the customer has agreed to buy a sneakers, or it is extremely clear that the user is not interested in talking to you or is annoyed by you)";
  const tabsData = [
    {
      name: "Agent Description",
      component: (
        <PromptEditor
          identifier="assistantDescription"
          value={formData.rulesConfig.prompts.assistantDescription}
          onEditorDataChange={handleEditorChange}
          helperText={agentDesctiptionText}
        />
      ),
    },
  ];

  return (
    <form>
      <Typography variant="h4" gutterBottom>
        Prompt Builder
      </Typography>
      <CustomTabs orientation={"vertical"} tabsData={tabsData} />
    </form>
  );
}

export default Prompts;

import React, { useEffect, useState } from "react";
import {
  TextField,
  FormControl,
  FormLabel,
  Select,
  MenuItem,
  InputLabel,
  Box,
  Tooltip,
  IconButton,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormGroup,
  Checkbox,
} from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { renderTooltip } from "../../components/CustomTooltip";
import leadQualificationJson from "../../data/templates/leadQualification.json";
import coachingJson from "../../data/templates/coaching.json";
import surveyJson from "../../data/templates/survey.json";
import recruitingJson from "../../data/templates/recruiting.json";
import salesAndMarketingJson from "../../data/templates/salesAndMarketing.json";
import customerServiceJson from "../../data/templates/customerService.json";
import virtualRMJson from "../../data/templates/virtualRM.json";
import otherJson from "../../data/templates/others.json";
import { Mixpanel } from "../../utils/mixpanel";

export function getPrefilledTemplate(option) {
  switch (option) {
    case "Lead Qualification":
      return leadQualificationJson["task_1"];
    case "Customer Service":
      return customerServiceJson["task_1"];
    case "Sales And Marketing":
      return salesAndMarketingJson["task_1"];
    case "Recruiting":
      return recruitingJson["task_1"];
    case "Survey/Feedback":
      return surveyJson["task_1"];
    case "VirtualRM":
      return virtualRMJson["task_1"];
    case "Coaching":
      return coachingJson["task_1"];
    case "Other":
      return otherJson["task_1"];
  }
}
function BasicConfiguration({ formData, onFormDataChange }) {
  //console.log(`INitial form data ${JSON.stringify(formData)}`)

  const handleChange = (event) => {
    Mixpanel.track(event.target.name, {
      item: event.target.value,
    });

    if (event.target.name === "assistantTask") {
      onFormDataChange({
        ...formData,
        basicConfig: {
          ...formData.basicConfig,
          [event.target.name]: event.target.value,
        },
        rulesConfig: {
          ...formData.rulesConfig,
          prompts: { ...getPrefilledTemplate(event.target.value) },
        },
      });
      return;
    } else if (event.target.name === "assistantType") {
      if (event.target.value == "IVR") {
        var newFormData = { ...formData };
        newFormData.modelsConfig.llmConfig.model = "gpt-3.5-turbo-1106";
        onFormDataChange({
          ...newFormData,
          basicConfig: {
            ...newFormData.basicConfig,
            [event.target.name]: event.target.value,
          },
        });
        return;
      }
    } else if (event.target.name === "optimizeLatency") {
      onFormDataChange({
        ...formData,
        basicConfig: {
          ...formData.basicConfig,
          [event.target.name]: event.target.checked,
        },
      });
      return;
    }

    onFormDataChange({
      ...formData,
      basicConfig: {
        ...formData.basicConfig,
        [event.target.name]: event.target.value,
      },
    });
  };

  const handleRadioChange = (event) => {
    Mixpanel.track("assistantInvocation", {
      item: event.target.value,
    });
    let engagementConfig = {};

    engagementConfig = {
      channel: event.target.value,
      format: "wav",
    };

    onFormDataChange({
      ...formData,
      engagementConfig: { ...engagementConfig },
    });
  };

  useEffect(() => {
    if (!formData.basicConfig.assistantTask) {
      handleChange({
        target: { name: "assistantTask", value: "Lead Qualification" },
      });
    }
  }, []);

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box
        sx={{ display: "flex", alignItems: "center", width: "50%", marginY: 1 }}
      >
        <FormControl fullWidth margin="normal">
          <TextField
            label="Agent Name"
            variant="outlined"
            name="assistantName"
            value={formData.basicConfig.assistantName}
            defaultValue="My Agent"
            onChange={handleChange}
          />
        </FormControl>
        {renderTooltip("Label to identify the agent")}
      </Box>

      <Box
        sx={{ display: "flex", alignItems: "center", width: "50%", marginY: 1 }}
      >
        <FormControl fullWidth margin="normal">
          <InputLabel id="assistant-type-label">Agent style</InputLabel>
          <Select
            labelId="assistant-type-label"
            id="assistant-type-select"
            name="assistantType"
            value={formData.basicConfig.assistantType || ""}
            label="Type of Assistant"
            onChange={handleChange}
          >
            <MenuItem value="FreeFlowing">Free Flowing</MenuItem>
            <MenuItem value="IVR">Intent Classification (IVR)</MenuItem>
          </Select>
        </FormControl>
        {renderTooltip(
          '"Free flowing" mimics a natural conversation while "Intent Classification" sticks to a pre-defined script.'
        )}
      </Box>

      <Box
        sx={{ display: "flex", alignItems: "center", width: "50%", marginY: 1 }}
      >
        <FormControl fullWidth margin="normal">
          <InputLabel id="task-label">Primary Task</InputLabel>
          <Select
            labelId="task-label"
            id="task-select"
            name="assistantTask"
            value={formData.basicConfig.assistantTask || "Lead Qualification"}
            label="Task for Assistant"
            onChange={handleChange}
          >
            <MenuItem value="Lead Qualification">Example One</MenuItem>
            <MenuItem value="Other">Other</MenuItem>
          </Select>
        </FormControl>
        {renderTooltip(
          "Your prompt template will be built according to the agent’s primary task. You do not need to stick to this task"
        )}
      </Box>

      <Box
        sx={{ display: "flex", alignItems: "center", width: "50%", marginY: 1 }}
      >
        <FormControl component="fieldset" fullWidth margin="normal">
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.basicConfig.optimizeLatency}
                  onChange={handleChange}
                  name="optimizeLatency"
                />
              }
              label="Enable Latency Optimizations"
            />
          </FormGroup>
        </FormControl>
        {renderTooltip(
          "This can bring enable human like conversation but on an average it's 3X more expensive."
        )}
      </Box>

      <Box
        sx={{ display: "flex", alignItems: "center", width: "50%", marginY: 1 }}
      >
        <FormControl component="fieldset" fullWidth margin="normal">
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <FormLabel
              component="legend"
              sx={{ fontWeight: "bold", marginRight: 2 }}
            >
              Agent Invocation
            </FormLabel>
            <RadioGroup
              row
              name="assistantInvocation"
              value={formData.engagementConfig.channel}
              onChange={handleRadioChange}
            >
              <FormControlLabel
                value="Telephone"
                control={<Radio />}
                label="Telephone"
              />
              <FormControlLabel
                value="Websocket"
                control={<Radio />}
                label="Websocket"
              />
            </RadioGroup>
            {renderTooltip(
              "How will the agent be invoked - through an outgoing phone call or via your system connecting using a websocket"
            )}
          </Box>
        </FormControl>
      </Box>

      <Box
        sx={{ display: "flex", alignItems: "center", width: "50%", marginY: 1 }}
      >
        {formData.engagementConfig.channel === "Telephone" && (
          <>
            <FormControl fullWidth margin="full">
              <TextField
                label="Agent Welcome Message"
                variant="outlined"
                name="agentWelcomeMessage"
                value={formData.basicConfig.agentWelcomeMessage}
                defaultValue="My Agent"
                onChange={handleChange}
              />
            </FormControl>
            {renderTooltip("Initial message said by your Agent.")}
          </>
        )}
        {formData.engagementConfig.channel === "Websocket" && (
          <FormControl fullWidth margin="full">
            <br />
            <br />
          </FormControl>
        )}
      </Box>
    </Box>
  );
}

export default BasicConfiguration;

import React, { useState } from "react";
import { createClient } from "@supabase/supabase-js";
import { toast } from "react-toastify";
import LoginAnimation from "./LoginAnimation.tsx";
import { Fit } from "@rive-app/react-canvas";

// Initialize the Supabase client
const options = {
  auth: {
    persistSession: true,
  },
};
const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_SECRET;
const redirectUrl =
  process.env.REACT_APP_REDIRECT_URL || "https://panel.outcall.app";

const supabase = createClient(supabaseUrl, supabaseAnonKey, options);

const SignUpForm = ({ setSession }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [isSignIn, setIsSignIn] = useState(true);

  const handleSignUp = async (e) => {
    e.preventDefault();
    try {
      let response;
      if (isSignIn) {
        response = await supabase.auth.signInWithPassword({
          email: email,
          password: password,
        });
      } else {
        response = await supabase.auth.signUp({
          email: email,
          password: password,
        });
      }

      if (response.error) {
        console.error("SignIn error:", response.error.message);
        toast.error(response.error.message, { position: "top-center" });
      } else {
        console.log("SignIn success:", response.user);
        setSession(response.session);
      }
    } catch (error) {
      setMessage(error.message);
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      const { data, error } = await supabase.auth.signInWithOAuth({
        provider: 'google',
        options: {
          redirectTo: redirectUrl
        }
      });
      if (error) throw error;
    } catch (error) {
      console.error("Google SignIn error:", error.message);
      toast.error(error.message, { position: "top-center" });
    }
  };

  const handleAppleSignIn = async () => {
    try {
      const { data, error } = await supabase.auth.signInWithOAuth({
        provider: 'apple',
        options: {
          redirectTo: redirectUrl
        }
      });
      if (error) throw error;
    } catch (error) {
      console.error("Apple SignIn error:", error.message);
      toast.error(error.message, { position: "top-center" });
    }
  };

  return (
    <div
      style={{ backgroundColor: "rgb(127,86,217)" }}
      className="flex flex-col lg:flex-row h-screen w-full antialiased"
    >
      <div
        style={{ flex: "3 1 auto" }}
        className="w-full h-[40%] lg:h-full lg:w-[59%] flex items-center justify-center "
      >
        <LoginAnimation asset={"/phone_interactive.riv"} fit={Fit.Contain} />
      </div>

      {/* Right side  */}
      <div
        style={{ flex: "2 0 auto" }}
        className=" w-full lg:w-[41%] lg:pl-[100px] lg:justify-start bg-white py-12  flex relative justify-center items-center  "
      >
        <div className="w-[66%] lg:w-[340px] flex flex-col justify-center ">
          <p className="text-black text-4xl pb-3 lg:pb-14 font-semibold">
            Outcall <span className="text-[#7F56D9] text-4xl ">AI</span>
          </p>
          <p className="text-sm text-[#888] font-normal mb-4">
            Express login via Google and Apple
          </p>

          <div className="flex  gap-x-0.5 mb-5">
            <button 
              onClick={handleGoogleSignIn}
              className="flex-1 bg-[#f1f1f1] cursor-pointer flex justify-between hover:bg-gray-300 items-center py-3  px-3 md:px-5 border-none rounded-l-lg"
            >
              <p className=" text-base text-[#888]"> Google</p>
              <div className="h-5 w-5 md:h-6 md:w-6">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="m0 0h24v24h-24" fill="#888" opacity="0" />
                  <path
                    d="m12 22h-.43a10.16 10.16 0 0 1 -9.57-9.71 10 10 0 0 1 14.12-9.41 1.48 1.48 0 0 1 .77.86 1.47 1.47 0 0 1 -.1 1.16l-1.29 2.38a1.44 1.44 0 0 1 -1.83.64 4.5 4.5 0 0 0 -4.9 1.08 4.41 4.41 0 0 0 -1.16 3.34 4.36 4.36 0 0 0 1.66 3 4.52 4.52 0 0 0 3.45 1 3.89 3.89 0 0 0 2.63-1.57h-2.9a1.45 1.45 0 0 1 -1.45-1.44v-2.68a1.45 1.45 0 0 1 1.45-1.45h8.1a1.46 1.46 0 0 1 1.45 1.44v1.88a10 10 0 0 1 -10 9.48zm0-18a8 8 0 0 0 -8 8.24 8.12 8.12 0 0 0 7.65 7.76 8 8 0 0 0 8.35-7.58v-1.22h-7v1.58h5.31l-.41 1.3a6 6 0 0 1 -4.9 4.25 6.58 6.58 0 0 1 -5-1.33 6.33 6.33 0 0 1 -.72-9.3 6.52 6.52 0 0 1 6.72-1.79l.77-1.43a7.9 7.9 0 0 0 -2.77-.48z"
                    fill="#888"
                  />
                </svg>
              </div>
            </button>
            <button 
              onClick={handleAppleSignIn}
              className="flex-1 bg-[#000000] cursor-pointer flex justify-between hover:bg-gray-900 items-center py-3 px-3 md:px-5 border-none rounded-r-lg"
            >
              <p className=" text-base text-white "> Apple</p>
              <div className="h-5 w-5 md:h-6 md:w-6">
                <svg viewBox="0 0 384 512" xmlns="http://www.w3.org/2000/svg">
                   <path fill="currentColor" d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z"/>
                </svg>
              </div>
            </button>
              
          </div>
          <hr className="mb-5 p-[1.5px] bg-gray-100 border-none rounded-xl" />
          <div className="flex gap-x-0 text-md ">
            <div
              className={`py-3 px-3 md:px-5 cursor-pointer flex justify-center text-base text-md items-center  text-center ${
                isSignIn
                  ? "bg-[#f1f1f1] text-md   font-semibold text-base rounded-t-lg border-none"
                  : "font-normal text-gray-500"
              }`}
              onClick={() => {
                setIsSignIn(true);
              }}
            >
              Log in
            </div>
            <div
              className={`py-3 px-3 md:px-5 cursor-pointer flex justify-center text-base  items-center text-center ${
                !isSignIn
                  ? "bg-[#f1f1f1]  text-md  font-semibold text-base rounded-t-lg border-none"
                  : "font-normal text-gray-500"
              }`}
              onClick={() => {
                setIsSignIn(false);
              }}
            >
              Sign up
            </div>
          </div>
          <div className="gap-y-0.5 flex flex-col mb-0.5">
            <form
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  console.log("enter");
                  handleSignUp(e);
                }
              }}
              onSubmit={(e) => {
                e.preventDefault();
                console.log("submitting");
                handleSignUp(e);
              }}
            >
              <input
                id="email-address"
                name="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className={`appearance-none rounded-tr-lg text-md text-base block w-full py-3 px-3 md:px-5 text-[#242424]  border border-none placeholder-[#888] focus:outline-none  bg-[#f1f1f1] focus:z-10  ${
                  !isSignIn ? " border-none  " : ""
                }`}
                placeholder="email"
              />
              <input
                type="password"
                id="password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                className="appearance-none block text-md text-base w-full py-3 px-3 md:px-5 border border-transparent text-[#242424] placeholder-[#888] focus:outline-none  bg-[#f1f1f1]  focus:z-10 "
                placeholder="password"
              />
            </form>
          </div>

          <button
            onClick={(e) => {
              handleSignUp(e);
            }}
            type="submit"
            className="w-full  py-3 px-3 md:px-5 bg-[#303030] text-md text-base font-semibold text-white rounded-b-xl border-0 mb-5"
            style={{ cursor: "pointer" }}
          >
            {isSignIn ? "Log in" : "Sign up"}
          </button>
          <div className="text-sm">
            {isSignIn ? (
              <div>
                <div className="text-center text-sm text-gray-600 mb-2">
                  Log in with SSO
                </div>
                <div className="text-center text-sm text-gray-600">
                  Forgot password?
                </div>
              </div>
            ) : (
              <div>
                <div className="text-center text-sm text-gray-600">
                  By signing up you agree to our Terms of Service and Privacy
                  Policy
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUpForm;

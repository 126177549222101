import React from 'react';
import { Box } from '@mui/material';

function Datasets() {

    return (
        <Box sx={{ display: 'flex' }}>
            <h1> Datasets </h1>
        </Box>
    );
}

export default Datasets;

import React, { useState } from 'react';
import { Snackbar, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Typography } from '@mui/material';
import { DialogContentText, DialogContent, Dialog, DialogTitle, DialogActions, Stack, Alert } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import createApiInstance from '../utils/api';
import { Mixpanel } from '../utils/mixpanel';
import TextField from '@mui/material/TextField';
import EventIcon from '@mui/icons-material/Event';
import moment from 'moment-timezone';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import SendIcon from '@mui/icons-material/Send';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

function formatHeader(header, row) {
    if (header.includes("_")) {
        return header.split('_').map(capitalizeFirstLetter).join(' ');
    }
    return header
}

function JsonTable({
    jsonData,
    columnsToShow,
    tooltipMap,
    actionsToShow = {},
    user,
    accessToken,
    onClickPage,
    clickable,
    headersDisplayedAs,
    agent,
    setToRefreshAfterDelete,
    dateColumns
}) {
    const navigate = useNavigate();
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [deleteTarget, setDeleteTarget] = useState(null);
    const [deleteApiUrl, setDeleteApiUrl] = useState(null);

    const [copyDialogOpen, setCopyDialogOpen] = useState(false);
    const [copiedRow, setCopiedRow] = useState(null);
    const [formBeingFilled, setFormBeingFilled] = useState(false);
    const [newAgentName, setNewAgentName] = useState('');
    const [infoMessage, setInfoMessage] = useState('');
    const [infoSeverity, setInfoSeverity] = useState('');

    const [scheduleDialogOpen, setScheduleDialogOpen] = useState(false);
    const [scheduleRow, setScheduleRow] = useState(null);
    const [scheduleInfoMessage, setScheduleInfoMessage] = useState('');
    const [scheduleInfoSeverity, setScheduleInfoSeverity] = useState('');

    const [runDialogOpen, setRunDialogOpen] = useState(false);
    const [runRow, setRunRow] = useState(null);
    const [runInfoMessage, setRunInfoMessage] = useState('');
    const [runInfoSeverity, setRunInfoSeverity] = useState('');

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const [selectedDateTime, setSelectedDateTime] = React.useState(dayjs());

    const api = createApiInstance(accessToken);

    const handleSnackbarClose = () => {
        setOpenSnackbar(false);
    };

    const handleFormFieldFocus = () => {
        setFormBeingFilled(true);
    };

    // Function to handle form field blur
    const handleFormFieldBlur = () => {
        setFormBeingFilled(false);
    };

    const handleDateTimeChange = (newDateTime) => {
        // Handle the date-time change if needed
        setSelectedDateTime(newDateTime);
    };

    const handleDateTimeSubmit = async (batchId, selectedDateTime, agentId) => {
        try {
            const formData = new FormData();
            formData.append('agent_id', agentId);
            formData.append('batch_id', batchId);
            formData.append('scheduled_at', moment(new Date(selectedDateTime)).format());

            const response = await api.post('/batches/schedule', formData);

            if (response.status === 200) {
                setScheduleInfoMessage("Your batch is scheduled successfully ...");
                setScheduleInfoSeverity('success');
                window.location.reload();
                // You can add additional logic or feedback here
            }
        } catch (error) {
            setScheduleInfoMessage(error?.response?.data?.message);
            setScheduleInfoSeverity('error');
        }
    };

    const handleBatchRun = async (batchId, agentId) => {
        try {
            const formData = new FormData();
            formData.append('agent_id', agentId);
            formData.append('batch_id', batchId);
            formData.append('is_scheduled', false);
            formData.append('scheduled_at', moment(new Date()).format());

            const response = await api.post('/batches/schedule', formData);

            if (response.status === 200) {
                setRunInfoMessage("Your batch is now running ...");
                setRunInfoSeverity('success');
                window.location.reload();
                // You can add additional logic or feedback here
            }
        } catch (error) {
            setRunInfoMessage(error?.response?.data?.message);
            setRunInfoSeverity('error');
        }
    };


    const handleDelete = async (e, accessToken, keyUuid, apiUrl, agentId) => {
        e.stopPropagation();
        try {
            let resourceId = keyUuid;
            if (apiUrl === '/batches') {
                resourceId = `${agentId}/${keyUuid}`;
            }
            const response = await api.delete(`${apiUrl}/${resourceId}`);

            if (response.data.state === "success") {
                setToRefreshAfterDelete(true);
            } else {
                console.error('Delete failed');
            }
        } catch (error) {
            console.error('Error while deleting:', error);
        } finally {
            // Close the delete confirmation dialog
            setDeleteDialogOpen(false);
            setDeleteTarget(null);
            setDeleteApiUrl(null);
        }
    };

    const handleCopy = async (e, agent_data) => {
        e.stopPropagation();
        try {
            const body = {
                agent_id: agent_data.agent_id,
                agent_name: newAgentName,
            };
            const response = await api.post('/agent/copy', body);

            if (response.data?.state && response.data.state === "success") {
                setInfoMessage("Agent copied successfully. Please wait ...");
                setInfoSeverity('success');
                setTimeout(() => {
                  window.location.reload(true);
                }, 5000);
            } else {
                setInfoMessage(response.data?.message);
                setInfoSeverity('error');
            }
        } catch (error) {
            console.error('Error while deleting:', error);
        }
    };

    const emailBatchData = async (e, keyUuid, apiUrl) => {
        setIsLoading(true);
        e.stopPropagation();
        try {
            const response = await api.get(`${apiUrl}/${keyUuid}/email`, {
                responseType: 'blob'
            });

            // Create a blob object from the response
            const blob = new Blob([response.data], { type: 'text/csv' });

            // Create a temporary URL to the blob
            const url = window.URL.createObjectURL(blob);

            // Create a link element
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${keyUuid}.csv`);

            // Append the link to the body
            document.body.appendChild(link);

            // Trigger the download
            link.click();

            // Cleanup
            window.URL.revokeObjectURL(url);
            setIsLoading(false);

        } catch (error) {
            console.error('Error while sending email:', error);
            setSnackbarMessage(error?.response?.data?.message);
            setOpenSnackbar(true);
        }
    };

    const handleDeleteClick = (e, keyUuid, apiUrl) => {
        // Show the delete confirmation dialog
        e.stopPropagation();
        setDeleteDialogOpen(true);
        setDeleteTarget(keyUuid);
        setDeleteApiUrl(apiUrl);
    };

    const handleScheduleClick = (e, batch_id, scheduled_at) => {
        // Show the delete confirmation dialog
        e.stopPropagation();
        setScheduleInfoMessage('Select a time to schedule your batch');
        setScheduleInfoSeverity('info');
        setScheduleDialogOpen(true);
        setScheduleRow({ batch_id, scheduled_at });
    };

    const handleScheduleDialogClose = (e) => {
        // Close the delete confirmation dialog without deleting
        e.stopPropagation();
        setScheduleDialogOpen(false);
        setScheduleRow(null);
        setScheduleInfoMessage('');
        setScheduleInfoSeverity('');
    };

    const handleRunClick = (e, batch_id, scheduled_at) => {
        // Show the delete confirmation dialog
        e.stopPropagation();
        setRunInfoMessage('');
        setRunInfoSeverity('');
        setRunDialogOpen(true);
        setRunRow({ batch_id, scheduled_at });
    };

    const handleRunDialogClose = (e) => {
        // Close the delete confirmation dialog without deleting
        e.stopPropagation();
        setRunDialogOpen(false);
        setRunRow(null);
        setRunInfoMessage('');
        setRunInfoSeverity('');
    };

    const handleCopyClick = (e, agent_id, agent_name) => {
        // Show the delete confirmation dialog
        e.stopPropagation();
        setCopyDialogOpen(true);
        setNewAgentName(`${agent_name} - Copy`);
        setInfoMessage('Please refresh the page if the copied agent doesn\'t appear');
        setInfoSeverity('info');
        setCopiedRow({ agent_id, agent_name });
    };

    const handleDeleteDialogClose = () => {
        // Close the delete confirmation dialog without deleting
        setDeleteDialogOpen(false);
        setDeleteTarget(null);
        setDeleteApiUrl(null);
    };

    const handleCopyDialogClose = (e) => {
        // Close the delete confirmation dialog without deleting
        e.stopPropagation();
        setCopyDialogOpen(false);
        setCopiedRow(null);
        setInfoMessage('');
        setInfoSeverity('');
    };

    const handleRowClick = (row, agentId) => {
        if (clickable && !formBeingFilled && !copyDialogOpen) {
            if (onClickPage === "agent-details") {
                Mixpanel.track('agent_click', {
                    'agent': row
                });

                navigate("/dashboard/agent-details", {
                    state: {
                        user: user,
                        agent: row
                    }
                });
            } else if (onClickPage === "run-details") {
                //console.log(`Row ${JSON.stringify(row)}`)
                navigate("/dashboard/agent/run-details", {
                    state: {
                        runDetails: row
                    }
                });
            } else if (onClickPage === "batch-details") {
                //console.log(`Row ${JSON.stringify(row)}`)
                navigate("/dashboard/agent/batch-details", {
                    state: {
                        agentId: agentId,
                        runDetails: row
                    }
                });
            }
        }
    };

    return (
        <TableContainer component={Paper} elevation={0} sx={{ boxShadow: 'none', backgroundColor: '#fff' }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        {headersDisplayedAs.map((column) => (
                            <TableCell key={column} sx={{ fontWeight: 'bold', color: 'grey[900]' }}>
                                {formatHeader(column, jsonData[0])}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {jsonData.map((row, index) => (
                        <TableRow onClick={() => handleRowClick(row, agent)} key={index} sx={{ '&:nth-of-type(odd)': { backgroundColor: '#f9f9f9' }, '&:nth-of-type(even)': { backgroundColor: '#fff' }, '&:last-child td, &:last-child th': { border: 0 }, '&:hover': { backgroundColor: '#e0e0e0' } }}>
                            {columnsToShow.map((column) => {
                                var name = row[column] == undefined ? "" : row[column]
                                if (dateColumns != undefined && dateColumns.includes(column)) {
                                    const options = {
                                        year: 'numeric',
                                        month: 'long',
                                        day: 'numeric',
                                        hour: 'numeric',
                                        minute: 'numeric',
                                        hour12: false
                                    };
                                    var d = new Date(name)

                                    name = new Date(name).toLocaleDateString(undefined, options)
                                }
                                if (tooltipMap && column in tooltipMap) {
                                    return (
                                        <Tooltip key={column} title={moment(row[tooltipMap[column]]).tz(moment.tz.guess()).format('YYYY-MM-DD HH:mm ZZ')} disableInteractive>
                                            <TableCell key={column}>{name.toString()}</TableCell>
                                        </Tooltip>
                                    )
                                } else {
                                    return (
                                        <TableCell key={column}>{name.toString()}</TableCell>
                                    )
                                }
                            })}

                            {Object.entries(actionsToShow).map(([key, value]) => {
                                return (
                                    <TableCell key={row[value]}>

                                        {/* Render content for "Delete" */}
                                        {key === 'Delete' && 'Email' in actionsToShow && (
                                            <>
                                            <IconButton onClick={(event) => handleDeleteClick(event, row[value.id], value.url)} aria-label={`${key} ${row[value.id]}`} sx={{ flexDirection: 'column' }}>
                                                <DeleteIcon />
                                                <Typography variant="caption">Delete</Typography>
                                            </IconButton>
                                            <IconButton onClick={(event) => emailBatchData(event, row[value.id], value.url)} aria-label={`${key} ${row[value.id]}`} sx={{ flexDirection: 'column' }}>
                                                <CloudDownloadIcon />
                                                <Typography variant="caption">Download CSV</Typography>
                                            </IconButton>

                                                {/* Snackbar */}
                                                <Snackbar
                                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                                                    open={openSnackbar}
                                                    autoHideDuration={6000} // Adjust as per your preference
                                                    onClose={handleSnackbarClose}
                                                    message={snackbarMessage}
                                                />
                                            </>
                                        )}

                                        {/* Render content for "Delete" */}
                                        {key === 'Delete' && !('Email' in actionsToShow) && (
                                            <IconButton onClick={(event) => handleDeleteClick(event, row[value.id], value.url)} aria-label={`${key} ${row[value.id]}`} sx={{ flexDirection: 'column' }}>
                                                <DeleteIcon />
                                                <Typography variant="caption">Delete</Typography>
                                            </IconButton>
                                        )}

                                        {key === 'Copy' && (
                                            <IconButton onClick={(event) => handleCopyClick(event, row[value.id], row[value.name])} aria-label={`${key} ${row[value.id]}`} sx={{ flexDirection: 'column' }}>
                                                <ContentCopyIcon />
                                                <Typography variant="caption">Copy</Typography>
                                            </IconButton>
                                        )}

                                        {/* Render content for "Schedule" */}

                                        {key === 'Schedule' && (
                                            <>
                                                <IconButton onClick={(event) => handleScheduleClick(event, row[value.id], row[value.scheduled_at])} aria-label={`${key} ${row[value.id]}`} sx={{ flexDirection: 'column', mr: '15px' }}>
                                                    <ScheduleSendIcon />
                                                    <Typography variant="caption">Schedule for later</Typography>
                                                </IconButton>
                                                <IconButton onClick={(event) => handleRunClick(event, row[value.id], row[value.scheduled_at])} aria-label={`${key} ${row[value.id]}`} sx={{ flexDirection: 'column' }}>
                                                    <SendIcon />
                                                    <Typography variant="caption">Run now</Typography>
                                                </IconButton>
                                            </>
                                        )}

                                    </TableCell>
                                );
                            })}

                            {/* Copy dialog */}
                            {setCopiedRow && (
                            <Dialog
                              open={copyDialogOpen}
                              onClose={(event) => handleCopyDialogClose(event)}
                              fullWidth
                              aria-labelledby="add-copy-dialog"
                            >
                                <DialogTitle>{copiedRow ? `Copy Agent - ${copiedRow.agent_name}` : 'Copy Agent'}</DialogTitle>
                                <DialogContent>

                                <Grid container spacing={0}>
                                  <Grid item xs={12}>
                                    <TextField
                                      autoFocus
                                      fullWidth
                                      margin="dense"
                                      id="agentName"
                                      label="Agent Name"
                                      type="text"
                                      onFocus={handleFormFieldFocus}
                                      onBlur={handleFormFieldBlur}
                                      defaultValue={copiedRow ? `${copiedRow.agent_name} - Copy` : ''}
                                      onChange={(e) => setNewAgentName(e.target.value)}
                                    />
                                  </Grid>
                                </Grid>

                                <Stack sx={{ width: '100%' }} spacing={2}>
                                  <Alert severity={infoSeverity}>{infoMessage}</Alert>
                                </Stack>

                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={(event) => handleCopy(event, copiedRow)} color="primary">
                                        Copy Agent
                                    </Button>
                                </DialogActions>
                            </Dialog>
                            )}

                            {/* Schedule dialog */}
                            {setScheduleRow && (
                                <Dialog
                                  open={scheduleDialogOpen}
                                  onClose={(event) => handleScheduleDialogClose(event)}
                                  aria-labelledby="schedule-dialog"
                                >
                                    <DialogTitle>Schedule Batch</DialogTitle>
                                    <DialogContent>

                                        <LocalizationProvider dateAdapter={AdapterDayjs} >

                                            {scheduleRow?.scheduled_at && scheduleRow.scheduled_at !== '' ? (
                                                <DateTimePicker
                                                    defaultValue={dayjs(scheduleRow.scheduled_at)}
                                                    onChange={handleDateTimeChange}
                                                    disablePast={true}
                                                    timeSteps={{minutes:30}}
                                                    slotProps={{
                                                        actionBar: {
                                                            actions: ["accept"]
                                                        }
                                                    }}
                                                    sx={{ width: '100%' }}
                                                />
                                            ) : (
                                                <DateTimePicker
                                                    onChange={handleDateTimeChange}
                                                    disablePast={true}
                                                    timeSteps={{minutes:30}}
                                                    slotProps={{
                                                        actionBar: {
                                                            actions: ["accept"]
                                                        }
                                                    }}
                                                    sx={{ width: '100%' }}
                                                />
                                            )}
                                        </LocalizationProvider><br/><br/>


                                    <Stack sx={{ width: '100%' }} spacing={2}>
                                      <Alert severity={scheduleInfoSeverity}>{scheduleInfoMessage}</Alert>
                                    </Stack>

                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={(event) => handleDateTimeSubmit(scheduleRow.batch_id, selectedDateTime, agent)} color="primary">
                                            Schedule batch
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            )}

                            {/* Batch run confirmation dialog */}
                            <Dialog open={runDialogOpen} onClose={handleRunDialogClose}>
                                <DialogTitle>Confirm Batch Run</DialogTitle>
                                <DialogContent>
                                    Are you sure you want to run this batch now? <br/><br/>

                                    <Stack sx={{ width: '100%' }} spacing={2}>
                                      <Alert severity={runInfoSeverity}>{runInfoMessage}</Alert>
                                    </Stack>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleRunDialogClose} color="primary">
                                        Cancel
                                    </Button>
                                    <Button onClick={(event) => handleBatchRun(runRow.batch_id, agent)} color="primary" autoFocus>
                                        Confirm
                                    </Button>
                                </DialogActions>
                            </Dialog>

                            {/* Delete confirmation dialog */}
                            <Dialog open={deleteDialogOpen} onClose={handleDeleteDialogClose}>
                                <DialogTitle>Confirm Deletion</DialogTitle>
                                <DialogContent>
                                    Are you sure you want to delete this?
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleDeleteDialogClose} color="primary">
                                        Cancel
                                    </Button>
                                    <Button onClick={(event) => handleDelete(event, accessToken, deleteTarget, deleteApiUrl, agent)} color="primary" autoFocus>
                                        Delete
                                    </Button>
                                </DialogActions>
                            </Dialog>

                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default JsonTable;

import React from "react";
import InputComponent from "../InputComponent";
import { Slider } from "@mui/material";
import { useState } from "react";
import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
const ConversationSettingsStep = ({
  delay,
  setDelay,
  numberOfWords,
  callHangupTime,
  setCallHangupTime,
  setNumberOfWords,
}) => {
  const [selectedValue, setSelectedValue] = useState("Via Prompt");
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };
  return (
    <div className="flex flex-col gap-y-7 pt-8 pb-6">
      <div className="w-full flex justify-center items-center relative pt-3">
        <p className="absolute  -top-[21%] left-[17%] text-sm text-black ">
          Linear delay for long pauses (in ms)
        </p>
        <div className="mr-3 w-3/5 flex items-center justify-center gap-x-3 relative">
          <Slider
            sx={{
              color: "primary.main",
              "& .MuiSlider-thumb": {
                backgroundColor: "black",
              },
              "& .MuiSlider-track": {
                backgroundColor: "black",
              },
              "& .MuiSlider-rail": {
                backgroundColor: "grey.700",
              },
            }}
            value={delay}
            onChange={(e, value) => setDelay(value)}
            step={50}
            min={100}
            max={2500}
            marks
            valueLabelDisplay="auto"
          />
        </div>
        <p className="">{delay}</p>
      </div>

      <div className=" mr-3 w-full flex justify-center items-center relative pt-3">
        <p className="absolute  -top-[21%] left-[17%] text-sm text-black ">
          Number of words to wait for before interrupting
        </p>
        <div className="w-3/5 mr-3 flex items-center justify-center gap-x-3 relative">
          <Slider
            sx={{
              color: "primary.main",
              "& .MuiSlider-thumb": {
                backgroundColor: "black",
              },
              "& .MuiSlider-track": {
                backgroundColor: "black",
              },
              "& .MuiSlider-rail": {
                backgroundColor: "grey.700",
              },
            }}
            name="interruptionAfter"
            value={numberOfWords}
            onChange={(e, value) => setNumberOfWords(value)}
            step={1}
            min={1}
            max={10}
            marks
            valueLabelDisplay="auto"
          />
        </div>
        <p className="">{numberOfWords}</p>
      </div>
      <div className="w-full flex justify-center items-center relative pt-3">
        <p className="absolute  -top-[21%] left-[17%] text-sm text-black ">
          Call Hangup Logic
        </p>
        <div className="w-3/5 flex items-center justify-center gap-x-3 relative">
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              value={selectedValue}
              onChange={handleChange}
              name="radio-buttons-group"
              sx={{
                "& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)": {
                  color: "black",
                },
                "& .MuiSvgIcon-root + .MuiSvgIcon-root": {
                  color: "black",
                },
              }}
            >
              <div className="flex">
                <FormControlLabel
                  value="Via Prompt"
                  control={<Radio />}
                  label="Via Prompt"
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      color: "black",
                    },
                    "&.Mui-checked": {
                      color: "black",
                    },
                  }}
                />
                <FormControlLabel
                  value="Static time based hangup"
                  control={<Radio />}
                  label="Static time based hangup"
                  sx={{
                    "&.Mui-checked": {
                      color: "black",
                    },
                    "& .MuiFormControlLabel-label": {
                      color: "black",
                    },
                  }}
                />
              </div>
            </RadioGroup>
          </FormControl>
        </div>
      </div>
      <div className="w-full flex justify-center items-center relative pt-3">
        <p className="absolute  -top-[21%] left-[17%] text-sm text-black ">
          Seconds of silence to wait for before hanging up (in seconds)
        </p>
        <div className="mr-3 w-3/5 flex items-center justify-center gap-x-3 relative">
          <Slider
            sx={{
              color: "primary.main",
              "& .MuiSlider-thumb": {
                backgroundColor: "black",
              },
              "& .MuiSlider-track": {
                backgroundColor: "black",
              },
              "& .MuiSlider-rail": {
                backgroundColor: "grey.700",
              },
            }}
            name="interruptionAfter"
            value={callHangupTime}
            onChange={(e, value) => setCallHangupTime(value)}
            step={1}
            min={1}
            max={30}
            marks
            valueLabelDisplay="auto"
          />
        </div>
        <p className="">{callHangupTime}</p>
      </div>
    </div>
  );
};

export default ConversationSettingsStep;

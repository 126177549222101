import React from "react";

const SupportIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 24 25"
      fill="none"
    >
      <rect width="24" height="24" fill="white" />
      <path
        d="M21 13H19C17.8954 13 17 13.8954 17 15V17C17 18.1046 17.8954 19 19 19V19C20.1046 19 21 18.1046 21 17V13C21 8.02944 16.9706 4 12 4C7.02944 4 3 8.02944 3 13V17C3 18.1046 3.89543 19 5 19V19C6.10457 19 7 18.1046 7 17V15C7 13.8954 6.10457 13 5 13H3"
        stroke="black"
        stroke-width="2"
      />
      <path
        d="M21 15V19C21 19 21 22 19 22C17 22 14 22 14 22"
        stroke="black"
        stroke-width="2"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default SupportIcon;

import React, { useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
const DropdownInput = ({ label, options, setSelectedOption }) => {
  const [inputValue, setInputValue] = useState(options[0]);
  const [showDropdown, setShowDropdown] = useState(false);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setInputValue(option);
    setShowDropdown(false);
  };

  return (
    <div className="w-full  relative pt-3">
      <label className="absolute -top-[21%] left-[20%] text-sm text-white">
        {label}
      </label>
      <div className="w-full flex justify-center items-center">
        <div className="relative  w-3/5">
          <input
            type="text"
            value={inputValue}
            className="border-2 border-solid border-black p-4 bg-white w-full rounded-xl text-left px-3 relative text-black"
            placeholder={label}
            onChange={handleInputChange}
            readOnly={true}
            onFocus={() => setShowDropdown(true)}
            onBlur={() => setTimeout(() => setShowDropdown(false), 200)} // to prevent dropdown from closing immediately on click
          />
          <ArrowDropDownIcon className="absolute top-1/4 cursor-pointer right-2" />
        </div>
      </div>

      {showDropdown && (
        <div className="absolute top-full left-1/2 z-50 transform -translate-x-1/2 shadow-lg w-3/5 bg-black z-50 text-white border border-black rounded-xl">
          {options.map((option, index) => (
            <div
              key={index}
              className="p-2 cursor-pointer hover:bg-gray-900 hover:text-white  border-2 border-solid hover:text-black border-black "
              onClick={() => handleOptionClick(option)}
            >
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default DropdownInput;

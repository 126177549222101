import React, { useEffect, useState, useRef } from "react";
import "./App.css";
import "./fonts/AvenirNext-Bold-01.ttf";
import "./fonts/AvenirNext-BoldItalic-02.ttf";
import "./fonts/AvenirNext-DemiBold-03.ttf";
import "./fonts/AvenirNext-DemiBoldItalic-04.ttf";
import "./fonts/AvenirNext-Heavy-09.ttf";
import "./fonts/AvenirNext-HeavyItalic-10.ttf";
import "./fonts/AvenirNext-Italic-05.ttf";
import "./fonts/AvenirNext-Medium-06.ttf";
import "./fonts/AvenirNext-MediumItalic-07.ttf";
import "./fonts/AvenirNext-Regular-08.ttf";
import "./fonts/AvenirNext-UltraLight-11.ttf";
import "./fonts/AvenirNext-UltraLightItalic-12.ttf";
import { createClient } from "@supabase/supabase-js";
import { Auth } from "@supabase/auth-ui-react";
import { ThemeSupa } from "@supabase/auth-ui-shared";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material/styles";
import MyAgents from "./pages/MyAgents";
import CreateAgents from "./pages/CreateAgents";
import AgentPrompt from "./components/AgentPrompt";
import Models from "./pages/Models";
import Datasets from "./pages/Datasets";
import Integrations from "./pages/Integrations";
import Account from "./pages/Account";
import Keys from "./pages/Keys";
import Dashboard from "./components/Dashboard";
import AgentDetails from "./pages/AgentDetails";
import RunDetails from "./pages/assistant-details/RunDetails";
import BatchDetails from "./pages/assistant-details/BatchDetails";
import createApiInstance from "./utils/api";
import { PaymentStatusPage } from "./utils/payment";
import { Mixpanel } from "./utils/mixpanel";
import { DialogContent, Dialog } from "@mui/material";
import { ToastContainer } from "react-toastify";
import { CircularProgress, Box } from "@mui/material";
import "react-toastify/dist/ReactToastify.css";
import SignUpForm from "./components/Authentication";
// Create a theme instance.
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff", // primary color for your layout
    },
    secondary: {
      main: "#fff", // secondary color
    },
    background: {
      default: "#06090F", // default background color
      paper: "#06090F", // background color for paper based components
    },
  },
  typography: {
    // Define any typography variants here
    h1: {
      fontSize: "2rem",
      fontWeight: 500,
    },
    // ...other variants
  },
  // You can also customize other theme aspects like spacing, breakpoints, etc.
});

const options = {
  auth: {
    persistSession: true,
  },
};
const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_SECRET;
const redirectUrl =
  process.env.REACT_APP_REDIRECT_URL || "https://panel.outcall.app";

const supabase = createClient(supabaseUrl, supabaseAnonKey, options);

function App() {
  const [session, setSession] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  const [isUserCalled, userCalled] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [agents, setAgents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const sentUserRequest = useRef(false);
  const [agentCreationCounter, setAgentCreationCounter] = useState(0);

  useEffect(() => {
    const fetchAgents = async (api) => {
      Mixpanel.track("login");
      setIsLoading(true);
      try {
        const response = await api.get(`/agent/all`);
        setAgents(response.data);
      } catch (error) {
        console.error("Error fetching agents: Making loading false", error);
        setIsLoading(false);
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };
    if (session?.access_token) {
      const api = createApiInstance(session?.access_token);
      fetchAgents(api);
    }
  }, [session?.access_token, agentCreationCounter]);
  useEffect(() => {
    if (sentUserRequest.current === true) {
      return;
    }
    sentUserRequest.current = true;
    const createUser = async (api) => {
      userCalled(true);
      try {
        const response = await api.post(`/user`);
        setShowPopup(true);
        console.log("createUser response:", response); // Debugging: Log the full response
        if (response.status === 200) {
          const userInfo = response.data.data;

          if (userInfo?.is_first_login) {
            Mixpanel.alias(userInfo.user_id);
            Mixpanel.track("signup");
            Mixpanel.people.set({
              $email: userInfo.user_info.email,
            });
          }

          setUserInfo(userInfo);
        } else {
          console.error("Failed to make user call");
        }
        setShowPopup(false);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    if (!session) {
      supabase.auth.getSession().then(({ data: { session } }) => {
        console.log("getSession response:", session); // Debugging: Log the session response
        setSession(session);
      });
      supabase.auth.onAuthStateChange((_event, session) => {
        console.log("onAuthStateChange event:", _event); // Debugging: Log the auth state change event
        console.log("onAuthStateChange session:", session); // Debugging: Log the session response
        setSession(session);
        if (session?.access_token && !isUserCalled) {
          const api = createApiInstance(session?.access_token);
          createUser(api);
        }
      });
    }
  }, [session, isUserCalled]);

  return (
    <ThemeProvider theme={theme} injectFirst className="">
      <ToastContainer />
      <Router className="App  ">
        {!showPopup && (
          <div className="bg-[#fff] w-screen min-h-screen ">
            <Routes className="bg-[#fff] min-h-screen ">
              <Route
                exact
                path="/"
                element={
                  !session || !session.access_token ? (
                    <header className="App-header">
                      <SignUpForm setSession={setSession} />
                    </header>
                  ) : (
                    <Navigate to="/dashboard/my-agents" />
                  )
                }
              />
              <Route
                path="/dashboard"
                className=" h-full w-full"
                element={
                  <Dashboard
                    agents={agents}
                    supabase={supabase}
                    userInfo={userInfo}
                    accessToken={session?.access_token}
                    user={userInfo}
                  />
                }
              >
                <Route
                  path="my-agents"
                  element={
                    <MyAgents
                      userCalled={userCalled}
                      agents={agents}
                      isLoading={isLoading}
                      error={error}
                      accessToken={session?.access_token}
                      user={userInfo}
                      setAgents={setAgents}
                      setError={setError}
                      setIsLoading={setIsLoading}
                      className="bg-[#fff]"
                      agentCreationCounter={agentCreationCounter}
                      setAgentCreationCounter={setAgentCreationCounter}
                    />
                  }
                />
                <Route
                  path="create-agents"
                  element={<CreateAgents accessToken={session?.access_token} />}
                />
                <Route
                  path="models"
                  element={
                    <Models
                      accessToken={session?.access_token}
                      user={userInfo}
                    />
                  }
                />
                <Route
                  path="datasets"
                  element={<Datasets session={session} />}
                />
                <Route
                  path="integrations"
                  element={<Integrations session={session} />}
                />
                <Route path="account" element={<Account session={session} />} />
                <Route
                  path="developer"
                  element={<Keys accessToken={session?.access_token} />}
                />
                <Route
                  path="agent-details"
                  element={<AgentDetails accessToken={session?.access_token} />}
                />
                <Route
                  path="agent/run-details"
                  element={<RunDetails accessToken={session?.access_token} />}
                />
                <Route
                  path="agent/batch-details"
                  element={
                    <BatchDetails
                      session={session}
                      accessToken={session?.access_token}
                    />
                  }
                />
                <Route
                  path="prompt"
                  session={session}
                  accessToken={session?.access_token}
                  element={<AgentPrompt accessToken={session?.access_token} />}
                />
                <Route path="user/payment" element={<PaymentStatusPage />} />
              </Route>
              <Route path="/login" element={<Navigate to="/" />} />
            </Routes>
          </div>
        )}

        {showPopup && (
          <div className=" bg-[#fff] w-screen min-h-screen">
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
              <Box sx={{ display: "flex-col" }}>
                <CircularProgress color="warning" />
              </Box>
            </div>
          </div>
        )}
      </Router>
    </ThemeProvider>
  );
}

export default App;

import React, { useRef, useEffect, useState } from "react";

function Subheader({ data, activeTab, setActiveTab }) {
  const tabRefs = useRef([]);
  const [tabStyles, setTabStyles] = useState({ width: 0, left: 0 });

  useEffect(() => {
    updateTabStyles(activeTab);
  }, [activeTab, data]);

  const updateTabStyles = (tab) => {
    const index = data.indexOf(tab);
    const tabElement = tabRefs.current[index];
    if (tabElement) {
      const { offsetWidth: width, offsetLeft: left } = tabElement;
      setTabStyles({ width, left });
    }
  };

  return (
    <div className="flex pt-8">
      <div className="flex flex-col w-full">
        <div className="w-full flex pb-2  gap-x-2  relative">
          {data.map((item, index) => (
            <div
              key={index}
              onClick={() => setActiveTab(item)}
              className="cursor-pointer"
              ref={(el) => (tabRefs.current[index] = el)}
            >
              <p
                className={`px-1 pb-1 ${
                  activeTab === item ? "text-black" : "text-gray-500"
                }`}
              >
                {item}
              </p>
            </div>
          ))}
          <div className="absolute bottom-0 left-0 w-full h-1 bg-gray-300">
            <div
              className="absolute h-1 bg-black transition-all duration-300"
              style={{
                width: `${tabStyles.width}px`,
                left: `${tabStyles.left}px`,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Subheader;

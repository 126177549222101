import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import VoicesAutocomplete from "./voicesAutocomplete";
import { getVoiceLabel } from "../utils/utils";
import InputComponent from "./InputComponent";
import { useState } from "react";
import axios from "axios";
const VoiceLabPrompt = ({ toggleShowVoiceLab, data }) => {
  const [text, setText] = useState("");
  const [selectedVoice, setSelectedVoice] = useState({ ...data[0] });
  const handleRawVoices = (event, newValue) => {
    setSelectedVoice(newValue);
  };
  const [audioSrc, setAudioSrc] = useState("");
  const [loading, setLoading] = useState(false);
  const handleSubmit = async () => {
    setLoading(true);
    try {
      let payload = {
        text: text,
        provider: selectedVoice.provider,
      };
      //console.log(`selectedVoice ${JSON.stringify(selectedVoice)}`)
      if (selectedVoice.provider == "polly") {
        payload.provider_config = {
          language: selectedVoice.languageCode,
          voice: selectedVoice.name,
          engine: selectedVoice.model,
        };
      } else if (selectedVoice.provider == "elevenlabs") {
        payload.provider_config = {
          model: selectedVoice.model,
          voice: selectedVoice.name,
          voice_id: selectedVoice.id,
        };
      } else if (selectedVoice.provider == "xtts") {
        payload.provider_config = {
          language: selectedVoice.languageCode,
          voice: selectedVoice.name,
        };
      } else if (selectedVoice.provider == "openai") {
        payload.provider_config = {
          model: selectedVoice.model,
          voice: selectedVoice.name,
        };
      } else if (selectedVoice.provider == "fourie") {
        payload.provider_config = {
          voice: selectedVoice.name,
          voice_id: selectedVoice.id,
          gender: selectedVoice.gender,
        };
      }

      console.log(
        `payload ${JSON.stringify(payload)} engine ${
          payload.provider_config.engine
        }`
      );

      const response = await axios.post(
        `${process.env.REACT_APP_FAST_API_BACKEND_URL}/tts`,
        payload
      );
      setLoading(false);
      console.log(`Got Base64 string ${JSON.stringify(response.data)}`);
      const audioUrl = `data:audio/wav;base64,${response.data.data}`;
      setAudioSrc(audioUrl);
    } catch (error) {
      console.error("Error fetching audio:", error);
      return "";
    }
  };
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 flex z-50 flex-col rounded-lg ">
      <div className="rounded-lg pb-4 shadow-lg w-[90%] md:w-[50%]   bg-white relative  ">
        <div className="flex justify-between items-center px-3  bg-black text-white py-4 rounded-lg ">
          <div>
            <h2 className="text-white">Voice Lab</h2>
          </div>
          <div
            className="cursor-pointer"
            onClick={() => {
              toggleShowVoiceLab();
            }}
          >
            <CloseIcon />
          </div>
        </div>
        <div className="flex justify-center pt-5">
          <VoicesAutocomplete
            voices={data}
            getVoiceLabel={getVoiceLabel}
            handleChange={handleRawVoices}
            selectedOption={selectedVoice}
          />
        </div>
        <div className="flex justify-center">
          <InputComponent
            label="Enter Text to listen"
            inputValue={text}
            setInputValue={(e) => setText(e.target.value)}
          />
        </div>
        <div className="flex gap-x-6 justify-center items-center pt-10">
          {audioSrc && <audio controls src={audioSrc} />}
        </div>
        <div className="flex gap-x-6 justify-center items-center pt-10">
          <button
            className=" bg-black text-white border-none px-4 cursor-pointer  py-2 rounded-md  "
            onClick={handleSubmit}
          >
            Try Voice
          </button>
          <button className=" bg-black text-white border-none px-4 cursor-pointer  py-2 rounded-md  ">
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default VoiceLabPrompt;

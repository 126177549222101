import React, { useState } from "react";

const InputComponent = ({ label, inputValue, setInputValue, type }) => {
  return (
    <div className="w-full flex flex-col justify-center items-center gap-y-1 relative pt-3">
      <div className="justify-left w-3/5 pl-1 ">
        <label className="text-sm text-black ">{label}</label>
      </div>
      <div className="flex justify-center w-full">
        <input
          type={type ? type : "text"}
          value={inputValue}
          className="border-2  border-solid border-black p-4 bg-white  w-3/5  rounded xl text-left px-3 relative text-black"
          placeholder={label}
          onChange={setInputValue}
        />
      </div>
    </div>
  );
};

export default InputComponent;

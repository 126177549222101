import React from "react";

const TextToSpeechIcon = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" fill="white" />
      <path
        d="M13.4773 14.501C13.2522 16.9963 11.3588 19.0152 8.91643 19.428L8.49976 19.4984V19.921V21.501H7.49976V19.921V19.4984L7.08308 19.428C4.64073 19.0152 2.74731 16.9963 2.5222 14.501H3.5276C3.64044 15.5105 4.09256 16.4577 4.81778 17.183C5.66169 18.0269 6.80628 18.501 7.99976 18.501C9.19323 18.501 10.3378 18.0269 11.1817 17.183C11.907 16.4577 12.3591 15.5105 12.4719 14.501H13.4773ZM21.0611 9.05261L21.061 9.05257L21.0558 9.05784L18.1568 11.9636L18.6617 10.134L18.8364 9.50098H18.1798H13.9998C13.6019 9.50098 13.2204 9.34294 12.9391 9.06164C12.6578 8.78033 12.4998 8.3988 12.4998 8.00098V4.00098C12.4998 3.60315 12.6578 3.22162 12.9391 2.94032C13.2204 2.65901 13.6019 2.50098 13.9998 2.50098H19.9998C20.3976 2.50098 20.7791 2.65901 21.0604 2.94032C21.3417 3.22162 21.4998 3.60315 21.4998 4.00098V8.00098C21.4998 8.41488 21.3351 8.78596 21.0611 9.05261ZM7.99976 7.50098C8.39758 7.50098 8.77911 7.65901 9.06042 7.94032C9.34172 8.22162 9.49976 8.60315 9.49976 9.00098V14.001C9.49976 14.3988 9.34172 14.7803 9.06042 15.0616C8.77911 15.3429 8.39758 15.501 7.99976 15.501C7.60193 15.501 7.2204 15.3429 6.9391 15.0616C6.65779 14.7803 6.49976 14.3988 6.49976 14.001V9.00098C6.49976 8.60315 6.65779 8.22162 6.9391 7.94032C7.2204 7.65901 7.60193 7.50098 7.99976 7.50098Z"
        fill="black"
        stroke="black"
      />
    </svg>
  );
};

export default TextToSpeechIcon;

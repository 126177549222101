import React from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Autocomplete,
} from "@mui/material";
import Paper from "@mui/material/Paper";

const VoicesAutocomplete = ({
  voices,
  getVoiceLabel,
  handleChange,
  selectedOption,
}) => {
  return (
    <Autocomplete
      className="pt-2 w-3/5 "
      options={voices}
      PaperComponent={({ children }) => (
        <Paper
          style={{
            background: "black",
            color: "white",
          }}
        >
          {children}
        </Paper>
      )}
      sx={{
        color: "white",
        "& .MuiAutocomplete-inputRoot": {
          backgroundColor: "white",
          color: "black",
          padding: "10px",
          border: "2px solid black",
          borderRadius: ".375rem",
          "& .MuiInputBase-input": {
            color: "black",

            padding: 0,
          },
          "&::placeholder": {
            color: "black",
          },
        },
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            border: "2px solid black",
          },
          "&:hover fieldset": {
            border: "2px solid black",
          },
          "&.Mui-focused fieldset": {
            border: "2px solid black",
          },
        },
        "& .MuiFormLabel-root": {
          color: "black",
          fontFamily: "Avenir Next",
          fontSize: "1.1rem",
          lineHeight: "1.25rem",
          fontWeight: "700",
        },
        "& .MuiInputLabel-root": {
          color: "black",
          "&.Mui-focused": {
            color: "black",
          },
        },

        "& .MuiAutocomplete-groupUl ": {
          color: "black",
        },
        "& .MuiAutocomplete-listbox": {
          backgroundColor: "black",

          "& .MuiAutocomplete-option": {
            color: "black",
          },
        },
        "& .MuiAutocomplete-popupIndicator": {
          color: "black",
        },
        "& .MuiAutocomplete-clearIndicator": {
          color: "black",
        },
      }}
      value={selectedOption ?? (voices && voices.length > 0 ? voices[0] : null)}
      name="voice"
      getOptionLabel={getVoiceLabel}
      filterOptions={(options, { inputValue }) => {
        return options.filter(
          (option) =>
            option.name?.toLowerCase().includes(inputValue.toLowerCase()) ||
            option.languageCode
              .toLowerCase()
              .includes(inputValue.toLowerCase()) ||
            option.model?.toLowerCase().includes(inputValue.toLowerCase()) ||
            option.provider?.toLowerCase().includes(inputValue.toLowerCase()) ||
            option.accent?.toLowerCase().includes(inputValue.toLowerCase())
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          className="text-white text-sm"
          label="Select Voice"
          variant="standard"
        />
      )}
      onChange={(event, newValue) => handleChange(event, newValue)}
      fullWidth
      margin="normal"
    />
  );
};

export default VoicesAutocomplete;

import { useRive, Alignment, Fit, Layout } from '@rive-app/react-canvas';
import React from 'react';

type Props = {
  asset: string;
  fit?: Fit;
};

export default function LoginAnimation({ asset, fit = Fit.Cover }: Props) {
  const params = {
    src: asset,
    stateMachines: ['social media state machine'],
    layout: new Layout({
      fit,
      alignment: Alignment.Center,
    }),
    autoplay: true,
  };

  const { RiveComponent } = useRive(params);

  return <RiveComponent />;
}
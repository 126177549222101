import React, { useState, useEffect } from "react";

import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Button,
  Dialog,
  CircularProgress,
} from "@mui/material";
import TableContents from "../components/icons/TableContents";
import CustomTabs from "../components/CustomTabs";
import ChatComponent from "../components/ChatComponent"; // Import your ChatComponent
import { convertToCreateAgentForm, convertToText } from "../utils/utils";
import axios from "axios";
import ASRModels from "./models/ASRModels";
import TTSModels from "./models/TTSModels";
import LLMModels from "./models/LLMModels";
import VoiceLab from "./models/VoiceLab";
import createApiInstance from "../utils/api";
import Subheader from "../components/icons/HeaderBreadCrumb/subheader";
import VoiceLabPrompt from "../components/VoiceLabPrompt";
function Models({ accessToken, user }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [openVoiceLabs, setOpenVoiceLabs] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [voices, setVoices] = useState([]);
  const [loading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [llmModels, setLLMModels] = useState(null);
  const api = createApiInstance(accessToken);
  const TTSheaders = ["Voices", "Model", "language", "Provider", "lowLatency"];
  const LLMheaders = ["Model Name", "language", "IVR Compatible"];
  const imgSources = [];
  const [rawVoices, setRawVoices] = useState([]);
  const extractFields = (data) => {
    return data.map((voice) => {
      imgSources.push("/" + voice.id + ".jpg");
      return {
        voice: voice.name,
        model: voice.model,
        languageCode: voice.languageCode,
        provider: voice.provider,
        lowLatency: "true",
      };
    });
  };
  const extractLLMFields = (data) => {
    return data.map((voice) => {
      return {
        modelName: voice.display_name,
        language: voice.languages,
        ivrCompatible: "Yes", // Assuming all entries are IVR compatible
      };
    });
  };
  const [activeSubHeader, setActiveSubHeader] = useState("TTS");
  const [showVoiceLab, setShowVoiceLab] = useState(false);
  const currentData = extractFields(voices);
  // const currentLLMData = extractLLMFields(llmModels);
  const handleOpenVoiceLabs = () => {
    setOpenVoiceLabs(true);
  };

  const handleCloseVoiceLabs = () => {
    setOpenVoiceLabs(false);
  };
  const handleRawVoices = (event, newValue) => {
    setRawVoices(newValue);
  };
  useEffect(() => {
    const fetchModels = async () => {
      setIsLoading(true);
      try {
        //const response = await axios.get(`${process.env.REACT_APP_FAST_API_BACKEND_URL}/get_voices?user_id=${userId}`);
        const response = await api.get("/get_all_voices");
        setVoices(response.data.voices);
        setLLMModels(response.data.llmModels);
        setRawVoices(response.data.voices);
        console.log(response.data);
        console.log(`Voices ${JSON.stringify(response.data)}`);
      } catch (error) {
        console.error("Error fetching agents:", error);
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };

    if (accessToken) {
      fetchModels();
    }
  }, [accessToken]);

  const tabsData = [
    { name: "TTS", component: <TTSModels voices={voices} /> },
    { name: "LLM", component: <LLMModels llmModels={llmModels} /> },
    { name: "ASR", component: <ASRModels /> },
  ];

  return (
    <div className="h-full w-full  text-black">
      <div className="flex justify-between items-center">
        <p className="text-3xl font-semibold">VoiceLab</p>

        <div
          className="bg-black text-white cursor-pointer flex items-center  rounded-md gap-x-2 py-0 px-4 py-2"
          onClick={() => {
            setShowVoiceLab(true);
          }}
        >
          <p className="">Voice Lab </p>
        </div>
      </div>

      {showVoiceLab && (
        <VoiceLabPrompt
          data={rawVoices}
          setShowVoiceLab={setShowVoiceLab}
          handleChange={handleRawVoices}
          toggleShowVoiceLab={setShowVoiceLab}
        />
      )}
      <div className={activeSubHeader === "TTS" ? "visible pt-10" : "hidden"}>
        <TableContents
          data={voices}
          currentData={currentData}
          headers={TTSheaders}
          user={user}
          imgSources={imgSources}
          copyTrue={false}
        />
      </div>
    </div>
  );
}

export default Models;

import React from "react";

const DetailWidget = ({ data }) => {
  return (
    <div className="flex flex-col bg-white  gap-y-3 rounded-xl border-solid border-[#1D2939] border-[1px]  justify-between w-full px-4 py-2">
      {Object.entries(data).map(([key, value]) => (
        <div key={key} className="flex justify-between">
          <div>
            <p className="text-black  ">{key}:</p>
          </div>
          <div className=" min-w-[80px] text-right">
            <p className="text-black ">
              {typeof value === "object" ? JSON.stringify(value) : value}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default DetailWidget;

import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Button,
  Dialog,
  CircularProgress,
} from "@mui/material";
import CustomTabs from "../../components/CustomTabs";
import axios from "axios";
import ExecutionMetadata from "./execution-details/ExecutionMetadata";
import ExecutionLogs from "./execution-details/ExecutionLogs";
import Subheader from "../../components/icons/HeaderBreadCrumb/subheader";
import createApiInstance from "../../utils/api";
import TableContents from "../../components/icons/TableContents";
function RunDetails({ accessToken }) {
  const location = useLocation();
  const navigate = useNavigate();
  const runDetails = location.state?.runDetails;
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("Metadata");
  const tabsData = [
    {
      name: "Execution Metadata",
      component: <ExecutionMetadata executionDetails={runDetails} />,
    },
    {
      name: "Execution Logs",
      component: (
        <ExecutionLogs accessToken={accessToken} runId={runDetails.id} />
      ),
    },
  ];
  const runId = runDetails.id;
  const extractFields = (data) => {
    return data.map((item) => {
      return {
        Timestamp: item.Time,
        Data: item.Data,
        Direction: item.Direction,
        Component: item.Component,
        Model: item.Model,
      };
    });
  };
  const headers = ["Timestamp", "Data", "Direction", "Component", "Model"];
  const api = createApiInstance(accessToken);
  const [executionLogs, setExecutionLogs] = useState([]);
  const currentData = extractFields(executionLogs);
  useEffect(() => {
    const fetchExecutionLogs = async () => {
      try {
        let [agentId, executionId] = runId.split("#");
        const response = await api.get(
          `/agent/${agentId}/execution/${executionId}/log`
        );
        setExecutionLogs(response.data.data);
        console.log("Execution Logs:", response.data.data);
      } catch (error) {
        console.error("Error fetching logs:", error);
      } finally {
        console.log("Execution Logs fetched successfully");
      }
    };
    fetchExecutionLogs();
  }, [accessToken]);

  return (
    <div className=" w-full bg-[06090F] text-black  ">
      <div className="flex justify-between items-center">
        <p className="text-4xl font-bold text-black">Agent Execution</p>
        <div className="flex items-center gap-x-3">
          <div className="cursor-pointer" onClick={() => {}}></div>
        </div>
      </div>
      <Subheader
        data={["Metadata", "Execution Logs"]}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
      <div className={activeTab === "Metadata" ? " visible" : "hidden"}>
        <ExecutionMetadata executionDetails={runDetails} />
      </div>
      <div
        className={
          activeTab === "Execution Logs" ? "w-full visible pt-8" : "hidden"
        }
      >
        <TableContents
          currentData={currentData}
          headers={headers}
          copyTrue={false}
          data={executionLogs}
          clickable={true}
        />
      </div>
    </div>
  );
}

export default RunDetails;
